
import React from 'react';
import Slider from 'react-slick';
import MarquesComponent from './MarquesItem'


const LogoSlider = ({logos, nbreLigneAfficher}) => {

    // Configuration du slider
    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 2000,
        responsive: [
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    infinite: true,
                    dots: true
                }
            }
        ]
    };

    // Diviser les logos en groupes pour chaque ligne
    //const nbreLigneAfficher = 2;
    const logosRows = logos.reduce((rows, key, index) => (index % nbreLigneAfficher === 0 ? rows.push([key]) : rows[rows.length - 1].push(key)) && rows, []);

    return (
        <div className="logo-slider">
           

            <Slider className="mx-3" {...settings}>
                {/* Afficher chaque ligne dans un slider */}
                {logosRows.map((rowData, rowIndex) => (
                    <div key={rowIndex} className="logo-row">
                        {/* Afficher chaque logo dans une colonne */}
                        <MarquesComponent rowData={rowData} rowIndex={rowIndex} />
                       
                    </div>
                ))}
            </Slider>
        </div>
    );
}

export default LogoSlider;



// import React from 'react';
// import Slider from 'react-slick';

// const LogoSlider = () => {
//     // Tableau d'objets pour les logos
//     const logos = [
//         { src: 'assets/img/logos/logo4.png', alt: 'Logo 4', href: '#' },
//         { src: 'assets/img/logos/logo5.png', alt: 'Logo 5', href: '#' },
//         { src: 'assets/img/logos/logo1.png', alt: 'Logo 1', href: '#' },
//         { src: 'assets/img/logos/logo2.png', alt: 'Logo 2', href: '#' },
//         { src: 'assets/img/logos/logo3.png', alt: 'Logo 3', href: '#' },
//         { src: 'assets/img/logos/logo6.png', alt: 'Logo 6', href: '#' },
//         { src: 'assets/img/logos/logo7.png', alt: 'Logo 7', href: '#' },
//         { src: 'assets/img/logos/logo8.png', alt: 'Logo 8', href: '#' },
//         { src: 'assets/img/logos/logo9.png', alt: 'Logo 9', href: '#' },
//         { src: 'assets/img/logos/logo10.png', alt: 'Logo 10', href: '#' }
//     ];

//     // Configuration du slider
//     const settings = {
//         dots: false,
//         infinite: true,
//         speed: 500,
//         slidesToShow: 4,
//         slidesToScroll: 1,
//         autoplay: true,
//         autoplaySpeed: 2000,
//         responsive: [
//             {
//                 breakpoint: 768,
//                 settings: {
//                     slidesToShow: 2,
//                     slidesToScroll: 2,
//                     infinite: true,
//                     dots: true
//                 }
//             }
//         ]
//     };

//     return (
//         <div className="logo-slider mt-4">
//             <Slider {...settings}>
//                 {logos.map((logo, index) => (
//                     <div key={index}>
//                         <a href={logo.href} className="logo">
//                             <img src={logo.src} alt={logo.alt} />
//                         </a>
//                     </div>
//                 ))}
//             </Slider>
//         </div>
//     );
// }

// export default LogoSlider;
