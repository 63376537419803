import React, { useState, useEffect } from 'react';
import { crudData } from '../../services/apiService';
import Breadcrumb from '../components/header/Breadcrumb';
import SliderSection from './SliderSection';
import CategoriesFamille from './CategoriesFamille';
import ProductSlider from './ProductSlider';
import Header from '../components/Header'; // Importation du composant Header
import Footer from '../components/Footer'; // Importation du composant Footer
import SearchNav from '../components/header/SearchNav';
import ProductsContent from './ProductsContent';

const products = [
  {
    id: 1,
    label: "new",
    imageUrl: "assets/img/products/prod58.png",
    title: "Xomie Remid 8 Sport Water Resistance Watch",
    price: "$579.00",
    rating: 4,
    ratingCount: 152,
  },
  {
    id: 2,
    label: "new",
    imageUrl: "assets/img/products/prod59.png",
    title: "Microte Surface 2.0 Laptop",
    price: "$979.00",
    rating: null,
    ratingCount: null,
  },
  {
    id: 3,
    label: null,
    imageUrl: "assets/img/products/prod60.png",
    title: "aPod Pro Tablet 2023 LTE + Wifi, GPS Cellular 12.9 Inch, 512GB",
    price: "$979.00 - $1,259.00",
    rating: null,
    ratingCount: null,
  },
  {
    id: 4,
    label: "save",
    imageUrl: "assets/img/products/prod61.png",
    title: "SROK Smart Phone 128GB, Oled Retina",
    price: "$579.00",
    oldPrice: "$779.00",
    rating: 4,
    ratingCount: 152,
  },
  {
    id: 5,
    label: null,
    imageUrl: "assets/img/products/prod60.png",
    title: "aPod Pro Tablet 2023 LTE + Wifi, GPS Cellular 12.9 Inch, 512GB",
    price: "$979.00 - $1,259.00",
    rating: null,
    ratingCount: null,
  }
];

const DetailFamille = () => {

  // Constante
  const apiUrl = "detailFamille.php";


  const [familleData, setFamilleData] = useState(null);
  const params = {
      mode: 'detailFamille',
      LG_FAMILLEID : localStorage.getItem('LG_FAMILLEID')
  };


  useEffect(() => {
    if (fetchData) { // Vérifiez que userData et UTITOKEN sont définis
      fetchData(params, apiUrl, setFamilleData);
    }
  }, [apiUrl]);


  // Fonction de recupération des détails de la famille
  const fetchData = (params, url, setData) => {
    crudData(params, url)
      .then(response => {
        setData(response.data);
      })
      .catch(error => {
        console.error('Erreur lors de la récupération des données:', error);
      });
  };

  let sliderData = null;
  if (familleData && familleData.sliderData) {
    sliderData = familleData.sliderData;
  }

  let typeProduitData = null;
  if (familleData && familleData.typeProduitData) {
    typeProduitData = familleData.typeProduitData;
  }


  console.log(sliderData);
  


    return (
        <>
        <div className="body-container profile-pg-1">
            <div className="container">
                <Header />
                <Breadcrumb
                    items={[
                        { text: "Home", link: "#" },
                        { text: "Pages", link: "#" },
                        { text: "nom de la famille ", link: "#" }
                    ]}
                />
                <SliderSection sliderData= {sliderData} />
                <CategoriesFamille typeProduitData={typeProduitData} />

                <ProductsContent />
                <ProductSlider products={products} />
            </div>
        </div>
        <div className='tc-footer-style3 mt-5'>
            <div className='container'>
                <Footer />
            </div>
        </div>
        </>
    );
}

export default DetailFamille;
