import React from 'react';
import { useTheme } from '../../../contexts/ThemeProvider';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSun, faMoon, faShoppingCart } from '@fortawesome/free-solid-svg-icons';
import { NavLink } from 'react-router-dom';

const Navbars = () => {

    const fullUrl = localStorage.getItem("fullUrl");


    const { theme, toggleTheme } = useTheme();

    const toggleButton = () => {
        toggleTheme();
    };

    // Tableau d'objets pour remplir la barre de navigation
    const navItems = [
        { label: 'Accueil', href: '/' },
        { label: 'A propos', href: '/apropos' },
        // {
        //     label: 'Homes',
        //     dropdown: [
        //         { label: 'Home Baby', href: 'https://ui-themez.smartinnovates.net/items/swoo_html/home_baby/index.html' },
        //         { label: 'Home Electronic', href: 'https://ui-themez.smartinnovates.net/items/swoo_html/home_electronic/index.html' },
        //         { label: 'Home Lighting', href: 'https://ui-themez.smartinnovates.net/items/swoo_html/home_lighting/index.html' },
        //         { label: 'Home Pets', href: 'https://ui-themez.smartinnovates.net/items/swoo_html/home_pets/index.html' },
        //         { label: 'Home Tech', href: 'index.html' }
        //     ]
        // },
        // {
        //     label: 'Pages',
        //     dropdown: [
        //         { label: 'About', href: 'https://ui-themez.smartinnovates.net/items/swoo_html/inner_pages/about.html' },
        //         { label: 'Contact', href: 'https://ui-themez.smartinnovates.net/items/swoo_html/inner_pages/contact.html' },
        //         { label: 'Login', href: 'https://ui-themez.smartinnovates.net/items/swoo_html/inner_pages/login.html' },
        //         { label: 'Profile', href: 'https://ui-themez.smartinnovates.net/items/swoo_html/inner_pages/profile.html' },
        //         { label: 'Register', href: 'https://ui-themez.smartinnovates.net/items/swoo_html/inner_pages/register.html' }
        //     ]
        // },
        // {
        //     label: 'Products',
        //     dropdown: [
        //         { label: 'Products', href: 'https://ui-themez.smartinnovates.net/items/swoo_html/inner_pages/products.html' },
        //         { label: 'Products Layout 2', href: 'https://ui-themez.smartinnovates.net/items/swoo_html/inner_pages/products_layout_2.html' },
        //         { label: 'Cart', href: 'https://ui-themez.smartinnovates.net/items/swoo_html/inner_pages/cart.html' },
        //         { label: 'Checkout', href: 'https://ui-themez.smartinnovates.net/items/swoo_html/inner_pages/checkout.html' },
        //         { label: 'Single Product', href: 'https://ui-themez.smartinnovates.net/items/swoo_html/inner_pages/single_product.html' },
        //         { label: 'Single Product Pay', href: 'https://ui-themez.smartinnovates.net/items/swoo_html/inner_pages/single_product_pay.html' }
        //     ]
        // },
        { label: 'Contact', href: '/contact' },
        { label: 'Nos catalogues', href: '/catalogue' }
    ];

    return (
        <nav className={"navbar navbar-expand-lg navbar-light tc-navbar-style3 " + theme}>
            <div className="container">
                <a className="navbar-brand">
                    <NavLink  to="/">
                        <img src={fullUrl + "assets/img/logo.png"} width={170} alt="" className="logo" />
                    </NavLink>
                </a>
                <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                </button>
                <div className="collapse navbar-collapse ms-lg-5" id="navbarSupportedContent">
                    <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                        {navItems && navItems.map((item, index) => (
                            <li key={index} className={`nav-item ${item.dropdown ? 'dropdown' : ''}`}>
                                {item.dropdown ? (
                                    <>
                                        <a className="nav-link dropdown-toggle" href="#" id={`navbarDropdown${index}`} role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                            {item.label}
                                        </a>
                                        <ul className="dropdown-menu" aria-labelledby={`navbarDropdown${index}`}>
                                            {item.dropdown.map((subItem, subIndex) => (
                                                <li key={subIndex}>
                                                    <a className="dropdown-item" href={subItem.href}>{subItem.label}</a>
                                                </li>
                                            ))}
                                        </ul>
                                    </>
                                ) : (
                                    <NavLink className="nav-link" to={item.href}>
                                        {item.label}
                                    </NavLink>
                                )}
                            </li>
                        ))}
                    </ul>
                </div>
                <div className="side-nav d-none d-lg-flex">
                    <div className="icons">
                        <a href="#" className="icon-link"> <i className="las la-sync-alt"></i> </a>
                        <a href="#" className="icon-link"> <i className="lar la-heart"></i> </a>
                        <a href="#" className="icon-link"> <i className="las la-user"></i> </a>
                    </div>
                    <div className="text-item ms-3">
                        <small className="fsz-11 color-666 text-uppercase d-block"> welcome </small>
                       
                        <a  className="fsz-14 text-uppercase fw-bold"> 
                        <NavLink  to="/admin"> log in </NavLink> / Register </a>
                        
                        
                    </div>
                    <div className="icons ms-4">
                        <a className="icon-link"> 
                            <div id="themeToggle" onClick={toggleButton}>
                                {theme === 'light' ? <FontAwesomeIcon icon={faMoon} /> : <FontAwesomeIcon icon={faSun} />}
                            </div>
                        </a>
                    </div>
                    <div className="text-item ms-3">
                        <small className="fsz-11 color-666 text-uppercase d-block"> cart </small>
                        <a href="https://ui-themez.smartinnovates.net/items/swoo_html/inner_pages/cart.html" className="fsz-14 text-uppercase fw-bold"> $1,689.00 </a>
                    </div>
                </div>
            </div>
        </nav>
    );
}

export default Navbars;
