import React from 'react';

const productsData = [
  {
    id: 1,
    discount: '$199.00',
    imageUrl: 'assets/img/products/prod26.png',
    rating: 5,
    reviews: 152,
    title: 'SROK Smart Phone 128GB, Oled Retina',
    price: '$579.00',
    oldPrice: '$859.00',
    shipping: 'free shipping',
    stockStatus: 'In stock',
  },
  {
    id: 2,
    newProduct: true,
    imageUrl: 'assets/img/products/prod27.png',
    rating: 5,
    reviews: 152,
    title: 'aPod Pro Tablet 2023 LTE + Wifi, GPS Cellular 12.9 Inch, 512GB',
    price: '$979.00 - $1,259.00',
    shipping: '$2.98 Shipping',
    stockStatus: 'In stock',
  },
  // Add more products as needed
];

const ProductsContent = () => {
  return (

    <section
  className="tc-products-style6 p-30 radius-4 bg-white mt-3 wow fadeInUp"
  style={{ visibility: "visible", animationName: "fadeInUp" }}
>
  <div className="row">

  <div className="col-lg-3">
      <div className="filters">
        {/* <div className="category-box">
          <h6 className="fsz-18 fw-bold text-uppercase mb-20"> categories </h6>
          <a
            href="#"
            className="cat-btn fsz-12 fw-bold py-2 px-3 bg-white radius-3 hover-bg-green2"
          >
            {" "}
            <i className="la la-angle-left me-2" /> All Categories{" "}
          </a>
          <div className="cat-list pt-20">
            <h6 className="fsz-14 fw-bold mb-20">
              {" "}
              Cell Phones &amp; Tablets{" "}
            </h6>
            <ul>
              <li>
                {" "}
                <a href="#"> All </a>{" "}
              </li>
              <li>
                {" "}
                <a href="#"> Iphone </a>{" "}
              </li>
              <li>
                {" "}
                <a href="#"> Samsung </a>{" "}
              </li>
              <li>
                {" "}
                <a href="#"> Xiaomi </a>{" "}
              </li>
              <li>
                {" "}
                <a href="#"> Asus </a>{" "}
              </li>
              <li>
                {" "}
                <a href="#"> Oppo </a>{" "}
              </li>
              <li>
                {" "}
                <a href="#"> Gaming Smartphone </a>{" "}
              </li>
              <li>
                {" "}
                <a href="#"> Ipad </a>{" "}
              </li>
              <li>
                {" "}
                <a href="#"> Window Tablets </a>{" "}
              </li>
              <li>
                {" "}
                <a href="#"> eReader </a>{" "}
              </li>
              <li>
                {" "}
                <a href="#"> Smartphone Chargers </a>{" "}
              </li>
              <li>
                {" "}
                <a href="#"> 5G Support Smartphone </a>{" "}
              </li>
              <li>
                {" "}
                <a href="#"> Smartphone Accessories </a>{" "}
              </li>
              <li>
                {" "}
                <a href="#"> Tablets Accessories </a>{" "}
              </li>
              <li>
                {" "}
                <a href="#">
                  {" "}
                  Cell Phones <i className="la la-angle-left" /> $200{" "}
                </a>{" "}
              </li>
            </ul>
          </div>
        </div> */}
        <div className="filter-box mt-10">
          <div className="title mb-20">
            <h6 className="fsz-18 fw-bold text-uppercase"> categories </h6>
            <a href="#" className="rest-btn hover-green2">
              {" "}
              <i className="la la-sync me-1" /> Reset All{" "}
            </a>
          </div>
          <div className="selected-filters">
            <span className="selected-item">
              {" "}
              Min: $45.00 <i className="la la-times ms-1" />{" "}
            </span>
            <span className="selected-item">
              {" "}
              10.9 inch <i className="la la-times ms-1" />{" "}
            </span>
            <span className="selected-item">
              {" "}
              Color: Red <i className="la la-times ms-1" />{" "}
            </span>
            <span className="selected-item">
              {" "}
              128GB <i className="la la-times ms-1" />{" "}
            </span>
          </div>
          <div className="filter-groups">
            <div className="filter-group">
              <div className="group-title">
                <strong className="fsz-14"> By Brands </strong>
                <span className="arrow">
                  {" "}
                  <i className="la la-angle-up" />{" "}
                </span>
              </div>
              <div className="group-body">
                <div className="search-group">
                  <input type="text" placeholder="" className="form-control" />
                  <button className="search-btn">
                    {" "}
                    <i className="la la-search" />{" "}
                  </button>
                </div>
                <div className="scroll-cont">
                  <div className="check-group check-brands">
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        defaultValue=""
                        id="brand1"
                      />
                      <label className="form-check-label" htmlFor="brand1">
                        <div className="d-inline-flex align-items-center">
                          <img
                            src="assets/img/brands/brand1.png"
                            alt=""
                            className="brand-img"
                          />
                          <span className="color-888 ms-2"> (14) </span>
                        </div>
                      </label>
                    </div>
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        defaultValue=""
                        id="brand2"
                      />
                      <label className="form-check-label" htmlFor="brand2">
                        <div className="d-inline-flex align-items-center">
                          <img
                            src="assets/img/brands/brand2.png"
                            alt=""
                            className="brand-img"
                          />
                          <span className="color-888 ms-2"> (6) </span>
                        </div>
                      </label>
                    </div>
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        defaultValue=""
                        id="brand3"
                      />
                      <label className="form-check-label" htmlFor="brand3">
                        <div className="d-inline-flex align-items-center">
                          <img
                            src="assets/img/brands/brand3.png"
                            alt=""
                            className="brand-img"
                          />
                          <span className="color-888 ms-2"> (7) </span>
                        </div>
                      </label>
                    </div>
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        defaultValue=""
                        id="brand4"
                      />
                      <label className="form-check-label" htmlFor="brand4">
                        <div className="d-inline-flex align-items-center">
                          <img
                            src="assets/img/brands/brand4.png"
                            alt=""
                            className="brand-img"
                          />
                          <span className="color-888 ms-2"> (18) </span>
                        </div>
                      </label>
                    </div>
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        defaultValue=""
                        id="brand5"
                      />
                      <label className="form-check-label" htmlFor="brand5">
                        <div className="d-inline-flex align-items-center">
                          <img
                            src="assets/img/brands/brand5.png"
                            alt=""
                            className="brand-img"
                          />
                          <span className="color-888 ms-2"> (1) </span>
                        </div>
                      </label>
                    </div>
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        defaultValue=""
                        id="brand6"
                      />
                      <label className="form-check-label" htmlFor="brand6">
                        <div className="d-inline-flex align-items-center">
                          <img
                            src="assets/img/brands/brand1.png"
                            alt=""
                            className="brand-img"
                          />
                          <span className="color-888 ms-2"> (14) </span>
                        </div>
                      </label>
                    </div>
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        defaultValue=""
                        id="brand7"
                      />
                      <label className="form-check-label" htmlFor="brand7">
                        <div className="d-inline-flex align-items-center">
                          <img
                            src="assets/img/brands/brand2.png"
                            alt=""
                            className="brand-img"
                          />
                          <span className="color-888 ms-2"> (7) </span>
                        </div>
                      </label>
                    </div>
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        defaultValue=""
                        id="brand8"
                      />
                      <label className="form-check-label" htmlFor="brand8">
                        <div className="d-inline-flex align-items-center">
                          <img
                            src="assets/img/brands/brand3.png"
                            alt=""
                            className="brand-img"
                          />
                          <span className="color-888 ms-2"> (18) </span>
                        </div>
                      </label>
                    </div>
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        defaultValue=""
                        id="brand9"
                      />
                      <label className="form-check-label" htmlFor="brand9">
                        <div className="d-inline-flex align-items-center">
                          <img
                            src="assets/img/brands/brand4.png"
                            alt=""
                            className="brand-img"
                          />
                          <span className="color-888 ms-2"> (4) </span>
                        </div>
                      </label>
                    </div>
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        defaultValue=""
                        id="brand10"
                      />
                      <label className="form-check-label" htmlFor="brand10">
                        <div className="d-inline-flex align-items-center">
                          <img
                            src="assets/img/brands/brand5.png"
                            alt=""
                            className="brand-img"
                          />
                          <span className="color-888 ms-2"> (1) </span>
                        </div>
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="filter-group">
              <div className="group-title">
                <strong className="fsz-14"> By Price </strong>
                <span className="arrow">
                  {" "}
                  <i className="la la-angle-up" />{" "}
                </span>
              </div>
              <div className="group-body">
                <div className="price-card">
                  <div className="slider">
                    <div className="progress" />
                  </div>
                  <div className="range-input">
                    <input
                      type="range"
                      className="range-min"
                      min={0}
                      max={10000}
                      defaultValue={0}
                      step={100}
                    />
                    <input
                      type="range"
                      className="range-max"
                      min={0}
                      max={10000}
                      defaultValue={10000}
                      step={100}
                    />
                  </div>
                  <div className="row mt-20 align-items-center gx-0">
                    <div className="col-9">
                      <div className="price-input">
                        <div className="field">
                          <span className="sympol">$</span>
                          <input
                            type="number"
                            className="input-min"
                            defaultValue={0}
                          />
                        </div>
                        <div className="separator" />
                        <div className="field">
                          <span className="sympol">$</span>
                          <input
                            type="number"
                            className="input-max"
                            defaultValue={10000}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-3 text-end">
                      <button className="bttn"> go </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="filter-group">
              <div className="group-title">
                <strong className="fsz-14"> By Rating </strong>
                <span className="arrow">
                  {" "}
                  <i className="la la-angle-up" />{" "}
                </span>
              </div>
              <div className="group-body">
                <div className="check-group check-stars">
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      defaultValue=""
                      id="rate1"
                    />
                    <label className="form-check-label" htmlFor="rate1">
                      <div className="rate ms-1">
                        <div className="stars">
                          <i className="la la-star" />
                          <i className="la la-star" />
                          <i className="la la-star" />
                          <i className="la la-star" />
                          <i className="la la-star" />
                        </div>
                        <span className="num fsz-12 ms-2"> (52) </span>
                      </div>
                    </label>
                  </div>
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      defaultValue=""
                      id="rate2"
                    />
                    <label className="form-check-label" htmlFor="rate2">
                      <div className="rate ms-1">
                        <div className="stars">
                          <i className="la la-star" />
                          <i className="la la-star" />
                          <i className="la la-star" />
                          <i className="la la-star" />
                          <i className="la la-star color-999" />
                        </div>
                        <span className="num fsz-12 ms-2"> (24) </span>
                      </div>
                    </label>
                  </div>
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      defaultValue=""
                      id="rate3"
                    />
                    <label className="form-check-label" htmlFor="rate3">
                      <div className="rate ms-1">
                        <div className="stars">
                          <i className="la la-star" />
                          <i className="la la-star" />
                          <i className="la la-star" />
                          <i className="la la-star color-999" />
                          <i className="la la-star color-999" />
                        </div>
                        <span className="num fsz-12 ms-2"> (5) </span>
                      </div>
                    </label>
                  </div>
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      defaultValue=""
                      id="rate4"
                    />
                    <label className="form-check-label" htmlFor="rate4">
                      <div className="rate ms-1">
                        <div className="stars">
                          <i className="la la-star" />
                          <i className="la la-star" />
                          <i className="la la-star color-999" />
                          <i className="la la-star color-999" />
                          <i className="la la-star color-999" />
                        </div>
                        <span className="num fsz-12 ms-2"> (1) </span>
                      </div>
                    </label>
                  </div>
                  {/* <div class="form-check">
                                                  <input class="form-check-input" type="checkbox" value="" id="rate5">
                                                  <label class="form-check-label" for="rate5">
                                                      <div class="rate ms-1">
                                                          <div class="stars">
                                                              <i class="la la-star"></i>
                                                              <i class="la la-star color-999"></i>
                                                              <i class="la la-star color-999"></i>
                                                              <i class="la la-star color-999"></i>
                                                              <i class="la la-star color-999"></i>
                                                          </div>
                                                          <span class="num fsz-12 ms-2"> (3) </span>
                                                      </div>
                                                  </label>
                                              </div> */}
                </div>
              </div>
            </div>
            <div className="filter-group">
              <div className="group-title">
                <strong className="fsz-14"> By Screen Size </strong>
                <span className="arrow">
                  {" "}
                  <i className="la la-angle-up" />{" "}
                </span>
              </div>
              <div className="group-body">
                <div className="check-boxes">
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      defaultValue=""
                      id="check1"
                    />
                    <label className="form-check-label" htmlFor="check1">
                      <span> 7” &amp; Under </span>
                    </label>
                  </div>
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      defaultValue=""
                      id="check2"
                    />
                    <label className="form-check-label" htmlFor="check2">
                      <span> 7.1” - 8.9” </span>
                    </label>
                  </div>
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      defaultValue=""
                      id="check3"
                    />
                    <label className="form-check-label" htmlFor="check3">
                      <span> 9” - 10.9” </span>
                    </label>
                  </div>
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      defaultValue=""
                      id="check4"
                    />
                    <label className="form-check-label" htmlFor="check4">
                      <span> 11” &amp; Greater </span>
                    </label>
                  </div>
                </div>
              </div>
            </div>
            <div className="filter-group">
              <div className="group-title">
                <strong className="fsz-14"> By Color </strong>
                <span className="arrow">
                  {" "}
                  <i className="la la-angle-up" />{" "}
                </span>
              </div>
              <div className="group-body">
                <div className="color-boxes">
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      defaultValue=""
                      id="color1"
                    />
                    <label className="form-check-label" htmlFor="color1">
                      <span className="color-box card-red">
                        <i className="la la-check" />
                      </span>
                    </label>
                  </div>
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      defaultValue=""
                      id="color2"
                    />
                    <label className="form-check-label" htmlFor="color2">
                      <span className="color-box card-blue">
                        <i className="la la-check" />
                      </span>
                    </label>
                  </div>
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      defaultValue=""
                      id="color3"
                    />
                    <label className="form-check-label" htmlFor="color3">
                      <span className="color-box card-cyan">
                        <i className="la la-check" />
                      </span>
                    </label>
                  </div>
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      defaultValue=""
                      id="color4"
                    />
                    <label className="form-check-label" htmlFor="color4">
                      <span className="color-box card-black">
                        <i className="la la-check" />
                      </span>
                    </label>
                  </div>
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      defaultValue=""
                      id="color5"
                    />
                    <label className="form-check-label" htmlFor="color5">
                      <span className="color-box card-white">
                        <i className="la la-check" />
                      </span>
                    </label>
                  </div>
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      defaultValue=""
                      id="color6"
                    />
                    <label className="form-check-label" htmlFor="color6">
                      <span className="color-box card-green">
                        <i className="la la-check" />
                      </span>
                    </label>
                  </div>
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      defaultValue=""
                      id="color7"
                    />
                    <label className="form-check-label" htmlFor="color7">
                      <span className="color-box card-gray">
                        <i className="la la-check" />
                      </span>
                    </label>
                  </div>
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      defaultValue=""
                      id="color8"
                    />
                    <label className="form-check-label" htmlFor="color8">
                      <span className="color-box card-purple">
                        <i className="la la-check" />
                      </span>
                    </label>
                  </div>
                </div>
              </div>
            </div>
            <div className="filter-group">
              <div className="group-title">
                <strong className="fsz-14"> By Memory </strong>
                <span className="arrow">
                  {" "}
                  <i className="la la-angle-up" />{" "}
                </span>
              </div>
              <div className="group-body">
                <div className="row">
                  <div className="col-lg-6">
                    <div className="check-group">
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          defaultValue=""
                          id="memory1"
                        />
                        <label
                          className="form-check-label fsz-13"
                          htmlFor="memory1"
                        >
                          {" "}
                          <p>
                            {" "}
                            12GB <span className="color-888"> (4) </span>{" "}
                          </p>{" "}
                        </label>
                      </div>
                    </div>
                    <div className="check-group">
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          defaultValue=""
                          id="memory2"
                        />
                        <label
                          className="form-check-label fsz-13"
                          htmlFor="memory2"
                        >
                          {" "}
                          <p>
                            {" "}
                            8GB <span className="color-888"> (3) </span>{" "}
                          </p>{" "}
                        </label>
                      </div>
                    </div>
                    <div className="check-group">
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          defaultValue=""
                          id="memory3"
                        />
                        <label
                          className="form-check-label fsz-13"
                          htmlFor="memory3"
                        >
                          {" "}
                          <p>
                            {" "}
                            6GB <span className="color-888"> (12) </span>{" "}
                          </p>{" "}
                        </label>
                      </div>
                    </div>
                    <div className="check-group">
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          defaultValue=""
                          id="memory4"
                        />
                        <label
                          className="form-check-label fsz-13"
                          htmlFor="memory4"
                        >
                          {" "}
                          <p>
                            {" "}
                            4GB <span className="color-888"> (6) </span>{" "}
                          </p>{" "}
                        </label>
                      </div>
                    </div>
                    <div className="check-group">
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          defaultValue=""
                          id="memory5"
                        />
                        <label
                          className="form-check-label fsz-13"
                          htmlFor="memory5"
                        >
                          {" "}
                          <p>
                            {" "}
                            3GB <span className="color-888"> (7) </span>{" "}
                          </p>{" "}
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="check-group">
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          defaultValue=""
                          id="memory6"
                        />
                        <label
                          className="form-check-label fsz-13"
                          htmlFor="memory6"
                        >
                          {" "}
                          <p>
                            {" "}
                            1.5GB <span className="color-888"> (1) </span>{" "}
                          </p>{" "}
                        </label>
                      </div>
                    </div>
                    <div className="check-group">
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          defaultValue=""
                          id="memory7"
                        />
                        <label
                          className="form-check-label fsz-13"
                          htmlFor="memory7"
                        >
                          {" "}
                          <p>
                            {" "}
                            1GB <span className="color-888"> (1) </span>{" "}
                          </p>{" "}
                        </label>
                      </div>
                    </div>
                    <div className="check-group">
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          defaultValue=""
                          id="memory8"
                        />
                        <label
                          className="form-check-label fsz-13"
                          htmlFor="memory8"
                        >
                          {" "}
                          <p>
                            {" "}
                            512MB <span className="color-888"> (2) </span>{" "}
                          </p>{" "}
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="filter-group">
              <div className="group-title">
                <strong className="fsz-14"> By Conditions </strong>
                <span className="arrow">
                  {" "}
                  <i className="la la-angle-up" />{" "}
                </span>
              </div>
              <div className="group-body">
                <div className="check-group">
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      defaultValue=""
                      id="con1"
                    />
                    <label className="form-check-label fsz-13" htmlFor="con1">
                      {" "}
                      <p>
                        {" "}
                        New <span className="color-888"> (21) </span>{" "}
                      </p>{" "}
                    </label>
                  </div>
                </div>
                <div className="check-group">
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      defaultValue=""
                      id="con2"
                    />
                    <label className="form-check-label fsz-13" htmlFor="con2">
                      {" "}
                      <p>
                        {" "}
                        Like New <span className="color-888"> (2) </span>{" "}
                      </p>{" "}
                    </label>
                  </div>
                </div>
                <div className="check-group">
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      defaultValue=""
                      id="con3"
                    />
                    <label className="form-check-label fsz-13" htmlFor="con3">
                      {" "}
                      <p>
                        {" "}
                        Open Box <span className="color-888"> (38) </span>{" "}
                      </p>{" "}
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="addimg mt-10">
          <img src="assets/img/addimg.png" alt="" className="img-cover" />
          <div className="inf">
            <h6 className="fsz-24 mb-30 text-white">
              {" "}
              <strong> OKODo </strong> hero 11+ <br /> 5K wireless{" "}
            </h6>
            <div className="price">
              <p className="fsz-12 color-999 text-uppercase"> from </p>
              <h5 className="fsz-30 fw-400"> $169 </h5>
            </div>
          </div>
        </div>
      </div>
    </div>
   
    <div className="col-lg-9">



    <div className="products-content">
      <div className="products">
        <div className="row">
          {productsData.map((product) => (
            <div key={product.id} className="col-lg-3">
              <div className="product-card">
                <div className="top-inf">
                  {product.discount && (
                    <div className="dis-card">
                      <small className="fsz-10 d-block text-uppercase">save</small>
                      <h6 className="fsz-14">{product.discount}</h6>
                    </div>
                  )}
                  {product.newProduct && (
                    <small className="fsz-10 py-1 px-2 radius-2 bg-222 text-white text-uppercase">
                      new
                    </small>
                  )}
                  <a href="#0" className="fav-btn">
                    <i className="las la-heart" />
                  </a>
                </div>
                <a href="../inner_pages/single_product.html" className="img">
                  <img src={product.imageUrl} alt="" className="img-contain main-image" />
                </a>
                <div className="info">
                  <div className="rating">
                    <div className="stars">
                      {Array.from({ length: product.rating }).map((_, index) => (
                        <i key={index} className="la la-star" />
                      ))}
                    </div>
                    <span className="num">({product.reviews})</span>
                  </div>
                  <h6>
                    <a
                      href="../inner_pages/single_product.html"
                      className="prod-title fsz-14 fw-bold mt-2 hover-green2"
                    >
                      {product.title}
                    </a>
                  </h6>
                  <div className="price mt-15">
                    <h5 className="fsz-18 color-red1 fw-600">
                      {product.price}
                      {product.oldPrice && (
                        <span className="old fsz-14 color-666 text-decoration-line-through">
                          {product.oldPrice}
                        </span>
                      )}
                    </h5>
                  </div>
                  <div className="meta">
                    <a href="#" className="meta-item color-green2">
                      {product.shipping} <span className="bg bg-green2" />
                    </a>
                  </div>
                  <p className="fsz-12 mt-2">
                    <i className="fas fa-check-circle color-green2 me-1" /> {product.stockStatus}
                  </p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>

    </div>
  </div>
</section>

  );
};

export default ProductsContent;
