import React from 'react';

function CardBox() {
    // Tableau d'objets contenant les informations des boîtes de cartes
    const cardItems = [
        {
            imgSrc: "assets/img/midea.jpg",
            brand: "xomia",
            title: "Sport Water Resistance Watch",
            link: "https://ui-themez.smartinnovates.net/items/swoo_html/inner_pages/single_product.html"
        },
        {
            imgSrc: "assets/img/midea.jpg",
            brand: "OKODo",
            title: "hero 11+ black",
            price: "$169"
        }
    ];

    return (
        <div className="col-lg-4 ps-lg-3">
            {cardItems.map((item, index) => (
                <div className="card-box mb-4" key={index}>
                    <div className="img">
                        <img src={item.imgSrc} alt="" className="img-cover" />
                    </div>
                    <div className={`info ${item.price ? 'text-white' : 'r_info'}`}>
                        {item.brand && <small className="fsz-10 d-block mb-2 text-uppercase">{item.brand}</small>}
                        <h6 className={`fsz-19 text-capitalize fw-600 ${item.price ? 'text-uppercase' : ''}`}>{item.title} {item.price && <><br /><span className="color-green2">{item.price}</span></>}</h6>
                        {item.link && <a href={item.link} className="butn px-3 py-2 bg-333 text-white radius-4 mt-30 fw-500 fsz-11 text-uppercase hover-bg-green2"><span>Shop Now</span></a>}
                    </div>
                </div>
            ))}
        </div>
    );
}

export default CardBox;
