import React, { useState, useEffect } from 'react';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import SearchNav from '../components/header/SearchNav';
import { crudData } from '../../services/apiService';
import { Modal, Button, Form } from 'react-bootstrap';
import Pagination from '../components/pagination/Pagination';
import {
  handleCreate,
  handleUpdate,
  handleDelete,
  handleInputChange,
  handleFileChange
} from '../../utils/formHandlers';

const HomeSectionList = () => {
  const fullUrl = localStorage.getItem("fullUrl");
  const apiUrl = "SectionProductManager.php";
  const apiloadFamilleUrl = "FamilleManager.php";

  // State variables
  const [currentPage, setCurrentPage] = useState(1);
  const productsPerPage = 8;
  const [familles, setFamilles] = useState([]);
  const [marquesData, setMarquesData] = useState([]);  // Initialize to empty array
  const [searchTerm, setSearchTerm] = useState("");
  const [editItem, setEditItem] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [posterPreview, setPosterPreview] = useState(null);
  const [logoPreview, setLogoPreview] = useState(null);

  // State for new marque object
  const [newMarque, setNewMarque] = useState({
    LG_HOMESECTIONID: '',
    STR_HOMESECTIONNAME: '',
    STR_HOMESECTIONBANNER: null,
  });

  // Fetch marques data
  useEffect(() => {
    fetchData({ mode: 'listSectionProduct' }, apiUrl, setMarquesData);
  }, []);

  // Fetch famille data
  useEffect(() => {
    fetchData({ mode: 'listFamille' }, apiloadFamilleUrl, setFamilles);
  }, []);

  // General fetch function
  const fetchData = (params, url, setData) => {
    crudData(params, url)
      .then(response => {
        setData(response.data.data);
      })
      .catch(error => {
        console.error('Erreur lors de la récupération des données:', error);
      });
  };

  // Function to refresh marques data
  const refreshData = () => {
    fetchData({ mode: 'listSectionProduct' }, apiUrl, setMarquesData);
  };

  // Function to toggle modal visibility and reset file previews
  const toggleModal = () => {
    setShowModal(!showModal);
    setPosterPreview(null);
    setLogoPreview(null);
  };

  // Handle edit item
  const handleEdit = (id) => {
    const itemToEdit = marquesData.find(marque => marque.LG_HOMESECTIONID === id);
    setEditItem(itemToEdit);
    setPosterPreview(itemToEdit.STR_HOMESECTIONBANNER ? fullUrl + `assets/img/homesection/${itemToEdit.STR_HOMESECTIONBANNER}` : null);
    //setLogoPreview(itemToEdit.STR_MARQUELOGO ? fullUrl + `assets/img/homesection/${itemToEdit.STR_MARQUELOGO}` : null);
    toggleModal();
  };

  // Filter products based on search term
const filteredProducts = marquesData.filter(catalogue =>
    catalogue.STR_HOMESECTIONNAME.toLowerCase().includes(searchTerm.toLowerCase())
  );
  
  // Pagination calculations
  const indexOfLastProduct = currentPage * productsPerPage;
  const indexOfFirstProduct = indexOfLastProduct - productsPerPage;
  const currentProducts = filteredProducts.slice(indexOfFirstProduct, indexOfLastProduct);
  const totalPages = Math.ceil(filteredProducts.length / productsPerPage);

  // Render loading if marques data is not fetched yet
  if (!marquesData) {
    return <div>Loading...</div>;
  }

  return (
    <div>
      <div className="tc-navbar-container-style3">
        <SearchNav searchTerm={searchTerm} setSearchTerm={setSearchTerm} />
      </div>
      <Button onClick={() => { setEditItem(null); toggleModal(); }}>Add Marque</Button>

      <section className="tc-new-brands-style3 p-30 radius-4 bg-white mt-3 wow fadeInUp slow">
        <div className="title mb-30">
          <div className="row align-items-center">
            <div className="col-lg-4 text-lg-end mt-3 mt-lg-0">
              <div className="arrows">
                <div className="swiper-button-next" tabIndex="0" role="button" aria-label="Next slide"></div>
                <div className="swiper-button-prev" tabIndex="0" role="button" aria-label="Previous slide"></div>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          {currentProducts.map((rowData, index) => (
            <div className="col-lg-6 mb-4" key={index}>
              <div className="product-card">
                <div className="top-inf">
                  <small className="fsz-10 py-1 px-2 radius-2 bg-222 text-white text-uppercase"> new </small>
                  <a className="fav-btn" onClick={() => handleEdit(rowData.LG_HOMESECTIONID)}>
                    <FontAwesomeIcon icon={faEdit} style={{ cursor: 'pointer' }} />
                  </a>
                  <a className="remove-btn" onClick={() => handleDelete(
                    { mode: 'deleteSectionProduct', LG_HOMESECTIONID: rowData.LG_HOMESECTIONID },
                    apiUrl,
                    refreshData)
                  }>
                    <FontAwesomeIcon icon={faTrashAlt} style={{ cursor: 'pointer' }} />
                  </a>
                </div>
                <a className="img">
                  <img src={ fullUrl + `assets/img/homesection/${rowData.STR_HOMESECTIONBANNER}`} alt="" className="img-contain main-image" />
                </a>
                <div className="info">
                  <div className="price mt-15">
                    <h5 className="fsz-18 fw-600"> {rowData.STR_HOMESECTIONNAME} </h5>
                  </div>
                  <h6> <a href="#" className="prod-title fsz-14 fw-bold mt-2 hover-green2">{rowData.STR_HOMESECTIONNAME}</a> </h6>
                  <p className="fsz-12 mt-2"> <i className="fas fa-check-circle color-green2 me-1"></i> En ligne </p>
                </div>
              </div>
            </div>
          ))}
        </div>
        <Pagination currentPage={currentPage} setCurrentPage={setCurrentPage} totalPages={totalPages} />
      </section>

      <Modal show={showModal} onHide={toggleModal}>
        <Modal.Header closeButton>
          <Modal.Title>{editItem ? 'Edit Marque' : 'Add Marque'}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form>
          <Form.Control
              as="select"
              value={editItem ? editItem.LG_FAMILLEID : newMarque.LG_FAMILLEID}
              onChange={(e) => handleInputChange(e, editItem ? setEditItem : setNewMarque, editItem ? editItem : newMarque)}
              name="LG_FAMILLEID"
            >
              <option value="">Sélectionnez une famille</option>
              {familles.map((famille) => (
                <option key={famille.LG_FAMILLEID} value={famille.LG_FAMILLEID}>
                  {famille.STR_FAMILLENAME}
                </option>
              ))}
            </Form.Control>
            <div className="form-group">
              <label htmlFor="STR_HOMESECTIONNAME">Nom de la marque</label>
              <input
                type="text"
                className="form-control"
                id="STR_HOMESECTIONNAME"
                name="STR_HOMESECTIONNAME"
                value={editItem ? editItem.STR_HOMESECTIONNAME : newMarque.STR_HOMESECTIONNAME}
                onChange={(e) => handleInputChange(e, editItem ? setEditItem : setNewMarque, editItem ? editItem : newMarque)}
              />
            </div>
            <div className="form-group">
              <label htmlFor="STR_HOMESECTIONBANNER">Poster:</label>
              <input
                type="file"
                className="form-control"
                id="STR_HOMESECTIONBANNER"
                onChange={(e) => handleFileChange(e, editItem ? setEditItem : setNewMarque, editItem ? editItem : newMarque, 'STR_HOMESECTIONBANNER', setPosterPreview)}
              />
              {posterPreview && <img src={posterPreview} alt="Poster Preview" style={{ width: '100px', height: 'auto', marginTop: '10px' }} />}
            </div>
            {/* <div className="form-group">
              <label htmlFor="STR_MARQUELOGO">Logo:</label>
              <input
                type="file"
                className="form-control"
                id="STR_MARQUELOGO"
                onChange={(e) => handleFileChange(e, editItem ? setEditItem : setNewMarque, editItem ? editItem : newMarque, 'STR_MARQUELOGO', setLogoPreview)}
              />
              {logoPreview && <img src={logoPreview} alt="Logo Preview" style={{ width: '100px', height: 'auto', marginTop: '10px' }} />}
            </div> */}
          </form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={toggleModal}>
            Close
          </Button>
          <Button className='radius-4 bg-green2 border-none' onClick={() => {
            const formData = new FormData();
            formData.append('LG_FAMILLEID', editItem ? editItem.LG_FAMILLEID : newMarque.LG_FAMILLEID);
            formData.append('STR_HOMESECTIONNAME', editItem ? editItem.STR_HOMESECTIONNAME : newMarque.STR_HOMESECTIONNAME);
            if (editItem) {
              formData.append('mode', "updateSectionProduct");
              formData.append('LG_HOMESECTIONID', editItem.LG_HOMESECTIONID);
              if (editItem.STR_HOMESECTIONBANNER instanceof File) {
                formData.append('STR_HOMESECTIONBANNER', editItem.STR_HOMESECTIONBANNER);
              }
              handleUpdate(formData ,apiUrl, toggleModal, refreshData);
            } else {
              formData.append('STR_HOMESECTIONBANNER', newMarque.STR_HOMESECTIONBANNER);
              formData.append('mode', "createSectionProduct");
              handleCreate(formData,apiUrl, toggleModal, refreshData);
            }
          }}>
            {editItem ? 'Save Changes' : 'Add Marque'}
          </Button>
        </Modal.Footer>
      </Modal>

      <ToastContainer />
    </div>
  );
};

export default HomeSectionList;
