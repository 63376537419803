import React, { useState } from 'react';
import Header from '../components/Header';
import Footer from '../components/Footer';
import CatalogueListe from './CatalogueListe';

const Catalogue = () => {
  const [searchTerm, setSearchTerm] = useState("");

  return (
    <>
      <div className="body-container">
        <div className="container">
          <Header />
          <div className='about-pg-1'>
            <CatalogueListe searchTerm={searchTerm} />
          </div>
        </div>
      </div>

      <div className='tc-footer-style3'>
        <div className='container'>
          <Footer />
        </div>
      </div>
    </>
  );
};

export default Catalogue;
