import React from 'react';
import { useTheme } from '../../contexts/ThemeProvider';
import LogoFooter  from './LogoFooter'
import { NavLink } from 'react-router-dom';

const footerData = {
    info: {
      title: "Swoo - 1st NYC tech online market",
      hotline: "hotline 24/7",
      phone: "(025) 3686 25 16",
      address: "257 Thatcher Road St, Brooklyn, Manhattan, NY 10092",
      email: "contact@Swootechmart.com",
      socialLinks: [
        { icon: "fab fa-twitter", url: "#" },
        { icon: "fab fa-facebook-f", url: "#" },
        { icon: "fab fa-instagram", url: "#" },
        { icon: "fab fa-youtube", url: "#" },
        { icon: "fab fa-pinterest", url: "#" },
      ],
    },
    menus: [
      {
        title: "Top Categories",
        links: [
          { name: "Laptops", url: "#" },
          { name: "PC & Computers", url: "#" },
          { name: "Cell Phones", url: "#" },
          { name: "Tablets", url: "#" },
          { name: "Gaming & VR", url: "#" },
          { name: "Networks", url: "#" },
          { name: "Cameras", url: "#" },
          { name: "Sounds", url: "#" },
          { name: "Office", url: "#" },
        ],
      },
      {
        title: "Company",
        links: [
          { name: "About Swoo", url: "#" },
          { name: "Contact", url: "#" },
          { name: "Career", url: "#" },
          { name: "Blog", url: "#" },
          { name: "Sitemap", url: "#" },
          { name: "Store Locations", url: "#" },
        ],
      },
      {
        title: "Help Center",
        links: [
          { name: "Customer Service", url: "#" },
          { name: "Policy", url: "#" },
          { name: "Terms & Conditions", url: "#" },
          { name: "Track Order", url: "#" },
          { name: "FAQs", url: "#" },
          { name: "My Account", url: "#" },
          { name: "Product Support", url: "#" },
        ],
      },
      {
        title: "Partner",
        links: [
          { name: "Become Seller", url: "#" },
          { name: "Affiliate", url: "#" },
          { name: "Advertise", url: "#" },
          { name: "Partnership", url: "#" },
        ],
      },
    ],
    footerBottom: {
      currency: "USD",
      languages: [{ name: "Eng", imgSrc: "assets/img/eng.png" }],
      subscribe: {
        title: "Subscribe & get 10% off for your first order",
        placeholder: "Enter your email address",
        buttonText: "Subscribe",
        policyText: "By subscribing, you’re accepted the our",
        policyLinkText: "Policy",
        policyLinkUrl: "#",
      },
    },
  };
  


const Footer = () => {
    const { info, menus, footerBottom } = footerData;
  const { theme, toggleTheme } = useTheme();



  return (
    <>
    <div className="footer-content mt-5">
      <div className="row">
        <div className="col-lg-4">
          <div className="foot-info">
            <h6 className="fsz-18 fw-bold mb-30 text-uppercase">{info.title}</h6>
            <p className="text-uppercase">{info.hotline}</p>
            <h4 className="fsz-30 fw-bold color-green2 mb-30">{info.phone}</h4>
            <p className="fsz-14 color-000">{info.address}</p>
            <a className="fsz-14 color-000" href={`mailto:${info.email}`}>{info.email}</a>
            <div className="foot-social mt-40">
              {info.socialLinks.map((link, index) => (
                <a key={index} href={link.url}><i className={link.icon}></i></a>
              ))}
            </div>
          </div>
        </div>
        {menus.map((menu, index) => (
          <div key={index} className="col-lg-2">
            <div className="links">
              <h6 className="fsz-18 fw-bold mb-30 text-uppercase">{menu.title}</h6>
              <ul className="links-list">
                {menu.links.map((link, index) => (
                  <li key={index}><a href={link.url}>{link.name}</a></li>
                ))}
              </ul>
            </div>
          </div>
        ))}
      </div>
      <div className="footer-btm-info mt-60">
        <div className="row">
          {/* <div className="col-lg-4">
            <div className="dropdowns">
              <ul className="links-ul">
                <li className="dropdown">
                  <a className="dropdown-toggle" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                    <i className="la la-dollar-sign me-1"></i> {footerBottom.currency}
                  </a>
                  <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                    <li><a className="dropdown-item" href="#">{footerBottom.currency}</a></li>
                    <li><a className="dropdown-item" href="#">{footerBottom.currency}</a></li>
                  </ul>
                </li>
                <li className="dropdown">
                  <a className="dropdown-toggle" href="#" id="navbarDropdown1" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                    <img src={footerBottom.languages[0].imgSrc} alt="" className="me-1 icon-15" /> {footerBottom.languages[0].name}
                  </a>
                  <ul className="dropdown-menu" aria-labelledby="navbarDropdown1">
                    <li><a className="dropdown-item" href="#">Arabic</a></li>
                  </ul>
                </li>
              </ul>
            </div>
          </div> */}
          {/* <div className="col-lg-8">
            <div className="subscribe-content">
              <h6 className="fsz-18 fw-bold mb-30 text-uppercase">{footerBottom.subscribe.title} <span className="color-red1"> 10% off </span></h6>
              <div className="form-group">
                <span className="icon"><i className="la la-envelope"></i></span>
                <input type="text" placeholder={footerBottom.subscribe.placeholder} />
                <button>{footerBottom.subscribe.buttonText} <i className="la la-long-arrow-right ms-2 fsz-18"></i></button>
              </div>
              <p className="fsz-13 fst-italic color-666 mt-2">{footerBottom.subscribe.policyText} <a href={footerBottom.subscribe.policyLinkUrl} className="color-000 text-decoration-underline">{footerBottom.subscribe.policyLinkText}</a></p>
            </div>
          </div> */}
        </div>
      </div>
    </div>

    </>
  );
}

export default Footer;
