import React from 'react';

const MarquesItem = ({ rowData, rowIndex }) => {
  const fullUrl = localStorage.getItem("fullUrl");
  
  return (
    <>
      {rowData.map((row, colIndex) => (
          <div key={colIndex} >
          <a href={row.href} className="logo mt-4">
              <img src={fullUrl + "assets/img/logos/"+row.STR_MARQUELOGO} height="30px"  alt={row.alt} />
          </a>
      </div>
      ))}
    </>
  );
};

export default MarquesItem;
