import './App.css';
import React from 'react';
import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';
import AchatComponents from './pages/paiement/AchatComponents';
import HistoriqueTicket from './pages/profile/HistoriqueTicket';
import Login from './pages/profile/Login';
import Detail from './pages/detail/Detail';
import WarningContent from './pages/Accueil/WarningContent';
import Accueil from './pages/Accueil/Accueil';
import DetailFamille from './pages/famille/DetailFamille';
import ContactPage from './pages/contact/ContactPage';

import Apropos from './pages/Apropos/Apropos'
import Catalogue from './pages/catalogue/Catalogue'
import Admin from './pages/admin/Admin';





function App() {
  return (

    <div>
      <Router basename="/">
        <Routes>
        {/* <Route path="/" element={<WarningContent />} /> */}
        <Route path="/" element={<Accueil />} />
        {/* <Route path="/accueil" element={<Accueil />} /> */}
        <Route path="/apropos" element={<Apropos />} />
        <Route path="/catalogue" element={<Catalogue />} />
        <Route path="/login" element={<Login />} />
        <Route path="/contact" element={<ContactPage />} />
        <Route path="/detail-famille" element={<DetailFamille />} />
        <Route exact path="/admin" element={<Admin />} />
        <Route path="/paiement" element={<AchatComponents />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
