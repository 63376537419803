import React from 'react';
import Breadcrumb from '../components/header/Breadcrumb';
import SliderSection from '../famille/SliderSection';
import CategoriesFamille from '../famille/CategoriesFamille';
import ContactSection from '../contact/ContactSection';
import Header from '../components/Header'; // Importation du composant Header
import Footer from '../components/Footer'; // Importation du composant Footer
import SearchNav from '../components/header/SearchNav';

const products = [
  {
    id: 1,
    label: "new",
    imageUrl: "assets/img/products/prod58.png",
    title: "Xomie Remid 8 Sport Water Resistance Watch",
    price: "$579.00",
    rating: 4,
    ratingCount: 152,
  },
  {
    id: 2,
    label: "new",
    imageUrl: "assets/img/products/prod59.png",
    title: "Microte Surface 2.0 Laptop",
    price: "$979.00",
    rating: null,
    ratingCount: null,
  },
  {
    id: 3,
    label: null,
    imageUrl: "assets/img/products/prod60.png",
    title: "aPod Pro Tablet 2023 LTE + Wifi, GPS Cellular 12.9 Inch, 512GB",
    price: "$979.00 - $1,259.00",
    rating: null,
    ratingCount: null,
  },
  {
    id: 4,
    label: "save",
    imageUrl: "assets/img/products/prod61.png",
    title: "SROK Smart Phone 128GB, Oled Retina",
    price: "$579.00",
    oldPrice: "$779.00",
    rating: 4,
    ratingCount: 152,
  },
  {
    id: 5,
    label: null,
    imageUrl: "assets/img/products/prod60.png",
    title: "aPod Pro Tablet 2023 LTE + Wifi, GPS Cellular 12.9 Inch, 512GB",
    price: "$979.00 - $1,259.00",
    rating: null,
    ratingCount: null,
  }
];

const DetailFamille = () => {
    return (
        <>
        <div className="body-container profile-pg-1">
            <div className="container">
                <Header />
                <Breadcrumb
                    items={[
                        { text: "Home", link: "#" },
                        { text: "Pages", link: "#" },
                        { text: "Admin", link: "#" }
                    ]}
                />
                <ContactSection />
                {/* <CategoriesFamille />
                <ProductSlider products={products} /> */}
            </div>
        </div>
        <div className='tc-footer-style3 mt-5'>
            <div className='container'>
                <Footer />
            </div>
        </div>
        </>
    );
}

export default DetailFamille;
