import React, { useState, useEffect } from "react";
import { fetchCatalogue } from '../../services/apiService';

const CatalogueListe = ({ searchTerm }) => {
  const fullUrl = localStorage.getItem("fullUrl");

  const [currentPage, setCurrentPage] = useState(1);
  const productsPerPage = 2;
  const [catalogueData, setCatalogueData] = useState(null);

  useEffect(() => {
    const params = {
      mode: 'listCatalogue',
    };

    fetchCatalogue(params)
      .then(response => {
        setCatalogueData(response.data.data);
      })
      .catch(error => {
        console.error('Erreur lors de la récupération des données:', error);
      });
  }, []); // Effectuer la récupération des données uniquement au montage

  if (!catalogueData) {
    return <div>Loading...</div>;
  }

  // Filtrer les produits en fonction du terme de recherche
  const filteredProducts = catalogueData.filter(catalogue =>
    catalogue.STR_CATALOGUENAME.toLowerCase().includes(searchTerm.toLowerCase())
  );

  // Calculate the products to display on the current page
  const indexOfLastProduct = currentPage * productsPerPage;
  const indexOfFirstProduct = indexOfLastProduct - productsPerPage;
  const currentProducts = filteredProducts.slice(indexOfFirstProduct, indexOfLastProduct);

  // Calculate total pages
  const totalPages = Math.ceil(filteredProducts.length / productsPerPage);

  // Create page numbers array
  const pageNumbers = [];
  for (let i = 1; i <= totalPages; i++) {
    pageNumbers.push(i);
  }

  return (
    <div>
      <section className="tc-new-brands-style3 p-30 radius-4 bg-white mt-3 wow fadeInUp slow">
        <div className="title mb-30">
          <div className="row align-items-center">
            <div className="col-lg-8">
              <h6 className="fsz-18 fw-bold text-uppercase">Nos catalogues</h6>
            </div>
            <div className="col-lg-4 text-lg-end mt-3 mt-lg-0">
              <div className="arrows">
                <div className="swiper-button-next" tabIndex="0" role="button" aria-label="Next slide"></div>
                <div className="swiper-button-prev" tabIndex="0" role="button" aria-label="Previous slide"></div>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          {currentProducts.map((catalogue) => (
            <div className="col-lg-3" key={catalogue.id}>
              <div className="product-card">
                <a href={catalogue.link} className="img th-230 d-block radius-4 overflow-hidden">
                  <img src={fullUrl + "assets/img/catalogue/" + catalogue.STR_CATALOGUEIMAGE} alt={catalogue.STR_CATALOGUENAME} className="img-cover" />
                </a>
                <div className="info pt-20">
                  <h6>
                    <a href={catalogue.link} className="fsz-16 fw-bold hover-green2">
                      {catalogue.STR_CATALOGUENAME}
                    </a>
                  </h6>
                  <p className="fsz-13 color-666 mt-10">{catalogue.description}</p>
                  <a
                    href={fullUrl +"assets/img/catalogue/" + catalogue.STR_CATALOGUEFILLE}
                    target="blank"
                    className="butn color-green2 border-green2 border radius-4 fw-500 fsz-11 text-uppercase text-center mt-2 hover-bg-green2 hover-white py-2 mt-20"
                  >
                    <span>Télécharger</span>
                  </a>
                </div>
              </div>
            </div>
          ))}
        </div>
        <div className="tc-products-style6">
          <ul className="pagination">
            {pageNumbers.map((number) => (
              <li key={number} className={`page-item ${number === currentPage ? 'active' : ''}`}>
                <a
                  className="page-link"
                  onClick={() => setCurrentPage(number)}
                >
                  {number}
                </a>
              </li>
            ))}
            {currentPage < totalPages && (
              <li className="page-item">
                <a className="page-link"  onClick={() => setCurrentPage(currentPage + 1)}>
                  Suivant <i className="la la-angle-right ms-1"></i>
                </a>
              </li>
            )}
          </ul>
        </div>
      </section>
    </div>
  );
};

export default CatalogueListe;
