import React, { useState, useRef, useEffect, useContext } from 'react';
import { useTheme } from '../../contexts/ThemeProvider';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSun, faMoon, faShoppingCart } from '@fortawesome/free-solid-svg-icons';
import { NavLink } from 'react-router-dom';
import LogoHeader from './LogoHeader';
import { HeaderContext } from '../../contexts/HeaderContext'; // Importer le contexte
import DropdownMenu from './DropdownMenu'; // Importer le composant DropdownMenu
import SearchNav from './header/SearchNav'; // Importer le composant SearchNav
import TopNavbar from './header/TopNavbar';
import Navbars from './header/Navbars';

function Header() {

  const { theme, toggleTheme } = useTheme();
  const [isSearchVisible, setIsSearchVisible] = useState(false);
  const [isCartVisible, setIsCartVisible] = useState(false); // Nouvel état pour le panier
  const [cartItems, setCartItems] = useState([]); // État local pour les éléments du panier
  const searchInputRef = useRef(null);
  const searchIconRef = useRef(null);
  const cartIconRef = useRef(null); // Référence pour l'icône du panier

  const { searchTerm, setSearchTerm } = useContext(HeaderContext); // Utiliser le contexte

  const toggleButton = () => {
    toggleTheme();
  };

  const toggleSearchVisibility = (event) => {
    event.preventDefault();
    setIsSearchVisible(!isSearchVisible);
    if (!isSearchVisible) {
      setTimeout(() => {
        searchInputRef.current.focus();
      }, 500); // delay to match the CSS transition duration
    }
    if (isCartVisible) {
      setIsCartVisible(false);
    }
  };

  const toggleCartVisibility = (event) => {
    event.preventDefault();
    setIsCartVisible(!isCartVisible);
    if (isSearchVisible) {
      setIsSearchVisible(false);
    }
  };

  const handleBlur = () => {
    // Add a timeout to allow click event on search icon to register before hiding the form
    setTimeout(() => {
      if (document.activeElement !== searchInputRef.current) {
        setIsSearchVisible(false);
      }
    }, 200);
  };

  const handleClickOutside = (event) => {
    if (
      searchInputRef.current &&
      !searchInputRef.current.contains(event.target) &&
      !searchIconRef.current.contains(event.target) &&
      cartIconRef.current &&
      !cartIconRef.current.contains(event.target)
    ) {
      setIsSearchVisible(false);
      setIsCartVisible(false); // Fermer le panier si clic en dehors
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  useEffect(() => {
    const storedCartItems = JSON.parse(localStorage.getItem('cartItems')) || [];
    setCartItems(storedCartItems);
  }, []);

  const isActiveFunc = (match, location) => {
    return match !== null;
  };

  return (
    <>
      <div className="tc-navbar-container-style3">
        <div className="wh-box p-15 radius-4 bg-white mt-20">
          {/* <TopNavbar /> */}
          <Navbars />
          {/* Inclure SearchNav ici et passer les props */}
          
        </div>
        <SearchNav searchTerm={searchTerm} setSearchTerm={setSearchTerm} />
      </div>
    </>
  );
}

export default Header;
