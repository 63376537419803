import React from 'react';

const Breadcrumb = ({ items }) => {
    return (
        <section className="tc-breadcrumb-style6 p-30 radius-4 bg-white mt-3 wow fadeInUp" style={{ visibility: "visible", animationName: "fadeInUp" }}>
            <nav aria-label="breadcrumb">
                <ol className="breadcrumb fw-bold mb-0">
                    {items.map((item, index) => (
                        <li key={index} className={index === items.length - 1 ? "breadcrumb-item active color-000" : "breadcrumb-item color-999"}>
                            {index === items.length - 1 ? (
                                <span>{item.text}</span>
                            ) : (
                                <a href={item.link}>{item.text}</a>
                            )}
                        </li>
                    ))}
                </ol>
            </nav>
        </section>
    );
}

export default Breadcrumb;
