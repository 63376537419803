import React from 'react';

const Pagination = ({ currentPage, setCurrentPage, totalPages }) => {
  const pageNumbers = Array.from({ length: totalPages }, (_, i) => i + 1);

  return (
    <div className="tc-products-style6">
      <ul className="pagination">
        {pageNumbers.map((number) => (
          <li key={number} className={`page-item ${number === currentPage ? 'active' : ''}`}>
            <a
              className="page-link"
              onClick={() => setCurrentPage(number)}
            >
              {number}
            </a>
          </li>
        ))}
        {currentPage < totalPages && (
          <li className="page-item">
            <a className="page-link"  onClick={() => setCurrentPage(currentPage + 1)}>
              Suivant <i className="la la-angle-right ms-1"></i>
            </a>
          </li>
        )}
      </ul>
    </div>
  );
};

export default Pagination;
