import React from 'react';
import Breadcrumb from '../components/header/Breadcrumb'
import ProfileSection from './ProfileSection'
import Header from '../components/Header'; // Importation du composant Header
import Footer from '../components/Footer'; // Importation du composant Header
import SearchNav from '../components/header/SearchNav';




const Admin = () => {
    return (

        <div class="body-container profile-pg-1">
            <div class="container">

                <Header /> 

                {/* <div className="tc-navbar-container-style3">
                    <SearchNav  />
                </div> */}

                <Breadcrumb
                    items={[
                        { text: "Home", link: "#" },
                        { text: "Pages", link: "#" },
                        { text: "Admin", link: "#" }
                    ]}
                />

                <ProfileSection />
                
            </div>
        </div>
    );
}

export default Admin;
