import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const NosAtous = () => {
  const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1
  };

  const sliderData = [
    {
      title: '100% authentic products',
      iconClass: 'la la-check-circle',
      text: 'Swoo Tech Mart just distribute 100% authorized products & guarantee quality. Nulla porta nulla nec orci vulputate, id rutrum sapien varius.'
    },
    {
      title: 'fast delivery',
      iconClass: 'la la-shipping-fast',
      text: 'Fast shipping with a lots of option to delivery. 100% guarantee that your goods alway on time and perserve quality.'
    },
    {
      title: 'affordable price',
      iconClass: 'la la-hand-holding-usd',
      text: 'We offer an affordable & competitive price with a lots of special promotions.'
    }
  ];

  return (
    <div className="tc-about-cards">
      <div className='row gx-2'>
        <Slider {...sliderSettings}>
          {sliderData.map((item, index) => (
            <div key={index} className="col-lg-4 mt-3">
              <div className="about-card wow fadeInUp h-100 d-flex flex-column">
                <div className="title mb-4">
                  <h6 className="fsz-18 fw-bold text-uppercase"> {item.title}</h6>
                  <span className="icon"> <i className={item.iconClass}></i> </span>
                </div>
                <div className="text fsz-14 color-666">
                  {item.text}
                </div>
              </div>
            </div>
          ))}
        </Slider>
      </div>
    </div>
  );
};

export default NosAtous;
