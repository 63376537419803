import React, { useState, useEffect } from 'react';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import SearchNav from '../components/header/SearchNav';
import { crudData } from '../../services/apiService';
import { Modal, Button, Form } from 'react-bootstrap';
import Pagination from '../components/pagination/Pagination';
import {
  handleCreate,
  handleUpdate,
  handleDelete,
  handleInputChange,
  handleFileChange
} from '../../utils/formHandlers';

const FamilleList = () => {
  const fullUrl = localStorage.getItem("fullUrl");
  const apiUrl = "FamilleManager.php";

  // State variables
  const [currentPage, setCurrentPage] = useState(1);
  const productsPerPage = 8;
  const [familles, setFamilles] = useState([]);
  const [marquesData, setMarquesData] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [editItem, setEditItem] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [posterPreview, setPosterPreview] = useState(null);
  const [logoPreview, setLogoPreview] = useState(null);

  // State for new marque object
  const [newMarque, setNewMarque] = useState({
    STR_FAMILLEICONE: '',
    STR_FAMILLENAME: '',
    STR_FAMILLEDESC: '',
    STR_FAMILLESMALLBANNER: null,
    STR_FAMILLEIMAGE: null,
  });

  // Fetch marques data
  useEffect(() => {
    fetchData({ mode: 'listFamille' }, apiUrl, setMarquesData);
  }, []);

  // General fetch function
  const fetchData = (params, url, setData) => {
    crudData(params, url)
      .then(response => {
        setData(response.data.data);
      })
      .catch(error => {
        console.error('Erreur lors de la récupération des données:', error);
      });
  };

  // Function to refresh marques data
  const refreshData = () => {
    fetchData({ mode: 'listFamille' }, apiUrl, setMarquesData);
  };

  // Function to toggle modal visibility and reset file previews
  const toggleModal = () => {
    setShowModal(!showModal);
    setPosterPreview(null);
    setLogoPreview(null);
  };

  // Handle edit item
  const handleEdit = (id) => {
    const itemToEdit = marquesData.find(marque => marque.LG_FAMILLEID === id);
    setEditItem(itemToEdit);
    setPosterPreview(itemToEdit.STR_FAMILLESMALLBANNER ? `assets/img/famille/${itemToEdit.STR_FAMILLESMALLBANNER}` : null);
    setLogoPreview(itemToEdit.STR_FAMILLEIMAGE ? `assets/img/famille/${itemToEdit.STR_FAMILLEIMAGE}` : null);
    toggleModal();
  };

  // Filter products based on search term
  const filteredProducts = marquesData.filter(catalogue =>
    catalogue.STR_FAMILLENAME.toLowerCase().includes(searchTerm.toLowerCase())
  );

  // Pagination calculations
  const indexOfLastProduct = currentPage * productsPerPage;
  const indexOfFirstProduct = indexOfLastProduct - productsPerPage;
  const currentProducts = filteredProducts.slice(indexOfFirstProduct, indexOfLastProduct);
  const totalPages = Math.ceil(filteredProducts.length / productsPerPage);

  // Render loading if marques data is not fetched yet
  if (!marquesData) {
    return <div>Loading...</div>;
  }

  return (
    <div>
      <div className="tc-navbar-container-style3">
        <SearchNav searchTerm={searchTerm} setSearchTerm={setSearchTerm} />
      </div>
      <Button onClick={() => { setEditItem(null); toggleModal(); }}>Add Marque</Button>

      <section className="tc-new-brands-style3 p-30 radius-4 bg-white mt-3 wow fadeInUp slow">
        <div className="title mb-30">
          <div className="row align-items-center">
            <div className="col-lg-4 text-lg-end mt-3 mt-lg-0">
              <div className="arrows">
                <div className="swiper-button-next" tabIndex="0" role="button" aria-label="Next slide"></div>
                <div className="swiper-button-prev" tabIndex="0" role="button" aria-label="Previous slide"></div>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          {currentProducts.map((rowData, index) => (
            <div className="col-lg-6 mb-4" key={index}>
              <div className="product-card">
                <div className="top-inf">
                  <small className="fsz-10 py-1 px-2 radius-2 bg-222 text-white text-uppercase"> new </small>
                  <a  className="fav-btn" onClick={() => handleEdit(rowData.LG_FAMILLEID)}>
                    <FontAwesomeIcon icon={faEdit} style={{ cursor: 'pointer' }} />
                  </a>
                  <a  className="remove-btn" onClick={() => handleDelete(
                    { mode: 'deleteFamille', LG_FAMILLEID: rowData.LG_FAMILLEID },
                    apiUrl,
                    refreshData)
                  }>
                    <FontAwesomeIcon icon={faTrashAlt} style={{ cursor: 'pointer' }} />
                  </a>
                </div>
                <a  className="img">
                <i className={`me-2 fs-6 ${rowData.STR_FAMILLEICONE}`}></i>
                  <img src={fullUrl + `assets/img/famille/${rowData.STR_FAMILLEIMAGE}`} alt="" className="img-contain main-image" />
                </a>
                <div className="info">
                  <div className="price mt-15">
                    <h5 className="fsz-18 fw-600"> {rowData.STR_FAMILLENAME} </h5>
                  </div>
                  <h6> <a href="#" className="prod-title fsz-14 fw-bold mt-2 hover-green2">{rowData.STR_FAMILLENAME}</a> </h6>
                  <p className="fsz-12 mt-2"> <i className="fas fa-check-circle color-green2 me-1"></i> En ligne </p>
                </div>
              </div>
            </div>
          ))}
        </div>
        <Pagination currentPage={currentPage} setCurrentPage={setCurrentPage} totalPages={totalPages} />
      </section>

      <Modal show={showModal} onHide={toggleModal}>
        <Modal.Header closeButton>
          <Modal.Title>{editItem ? 'Edit Marque' : 'Add Marque'}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form>
            <div className="form-group">
              <label htmlFor="STR_FAMILLENAME">Nom de la famille</label>
              <input
                type="text"
                className="form-control"
                id="STR_FAMILLENAME"
                name="STR_FAMILLENAME"
                value={editItem ? editItem.STR_FAMILLENAME : newMarque.STR_FAMILLENAME}
                onChange={(e) => handleInputChange(e, editItem ? setEditItem : setNewMarque, editItem ? editItem : newMarque)}
              />
            </div>
            <div className="form-group">
              <label htmlFor="STR_FAMILLEICONE">Famille icon</label>
              <input
                type="text"
                className="form-control"
                id="STR_FAMILLEICONE"
                name="STR_FAMILLEICONE"
                value={editItem ? editItem.STR_FAMILLEICONE : newMarque.STR_FAMILLEICONE}
                onChange={(e) => handleInputChange(e, editItem ? setEditItem : setNewMarque, editItem ? editItem : newMarque)}
              />
            </div>

            <div className="form-group">
              <label htmlFor="STR_FAMILLEDESC">Description</label>
              <input
                type="text"
                className="form-control"
                id="STR_FAMILLEDESC"
                name="STR_FAMILLEDESC"
                value={editItem ? editItem.STR_FAMILLEDESC : newMarque.STR_FAMILLEDESC}
                onChange={(e) => handleInputChange(e, editItem ? setEditItem : setNewMarque, editItem ? editItem : newMarque)}
              />
            </div>

            <div className="form-group">
              <label htmlFor="STR_FAMILLESMALLBANNER">Banner:</label>
              <input
                type="file"
                className="form-control"
                id="STR_FAMILLESMALLBANNER"
                onChange={(e) => handleFileChange(e, editItem ? setEditItem : setNewMarque, editItem ? editItem : newMarque, 'STR_FAMILLESMALLBANNER', setPosterPreview)}
              />
              {posterPreview && <img src={posterPreview} alt="Poster Preview" style={{ width: '100px', height: 'auto', marginTop: '10px' }} />}
            </div>
            <div className="form-group">
              <label htmlFor="STR_FAMILLEIMAGE">image famille:</label>
              <input
                type="file"
                className="form-control"
                id="STR_FAMILLEIMAGE"
                onChange={(e) => handleFileChange(e, editItem ? setEditItem : setNewMarque, editItem ? editItem : newMarque, 'STR_FAMILLEIMAGE', setLogoPreview)}
              />
              {logoPreview && <img src={logoPreview} alt="Logo Preview" style={{ width: '100px', height: 'auto', marginTop: '10px' }} />}
            </div>
          </form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={toggleModal}>
            Close
          </Button>
          <Button className='radius-4 bg-green2 border-none' onClick={() => {
            const formData = new FormData();
            formData.append('STR_FAMILLEICONE', editItem ? editItem.STR_FAMILLEICONE : newMarque.STR_FAMILLEICONE);
            formData.append('STR_FAMILLENAME', editItem ? editItem.STR_FAMILLENAME : newMarque.STR_FAMILLENAME);
            formData.append('STR_FAMILLEDESC', editItem ? editItem.STR_FAMILLEDESC : newMarque.STR_FAMILLEDESC);
            
            if (editItem) {
              formData.append('mode', "updateFamille");
              formData.append('LG_FAMILLEID', editItem.LG_FAMILLEID);
              if (editItem.STR_FAMILLESMALLBANNER instanceof File) {
                formData.append('STR_FAMILLESMALLBANNER', editItem.STR_FAMILLESMALLBANNER);
              }
              if (editItem.STR_FAMILLEIMAGE instanceof File) {
                formData.append('STR_FAMILLEIMAGE', editItem.STR_FAMILLEIMAGE);
              }
              handleUpdate(formData, apiUrl, toggleModal, refreshData);
            } else {
              formData.append('STR_FAMILLESMALLBANNER', newMarque.STR_FAMILLESMALLBANNER);
              formData.append('STR_FAMILLEIMAGE', newMarque.STR_FAMILLEIMAGE);
              formData.append('mode', "createFamille");
              handleCreate(formData, apiUrl, toggleModal, refreshData);
            }
          }}>
            {editItem ? 'Save Changes' : 'Add Marque'}
          </Button>
        </Modal.Footer>
      </Modal>

      <ToastContainer />
    </div>
  );
};

export default FamilleList;
