import React from 'react';
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";

const ProductSlider = ({ products }) => {
  const fullUrl = localStorage.getItem("fullUrl");


  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1
  };

  return (
    <section className="tc-recently-viewed-style6 p-30 radius-4 bg-white mt-3 wow fadeInUp mb-3">
      <div className="title mb-30">
        <div className="row align-items-center">
          <div className="col-lg-8">
            <h6 className="fsz-18 fw-bold text-uppercase d-inline-block">
              Your Recently Viewed
            </h6>
            <a href="#" className="more text-capitalize color-666 fsz-13 ms-lg-4 mt-4 mt-lg-0">
              View All <i className="la la-angle-right ms-1" />
            </a>
          </div>
          <div className="col-lg-4 text-lg-end mt-3 mt-lg-0">
            <div className="arrows">
              <div className="swiper-button-next swiper-button-disabled" />
              <div className="swiper-button-prev" />
            </div>
          </div>
        </div>
      </div>
      <Slider {...settings}>
        {products.map(product => (
          <div key={product.id} className="product-card">
            <div className="top-inf">
              {product.label && (
                <small className="fsz-10 py-1 px-2 radius-2 bg-222 text-white text-uppercase">
                  {product.label}
                </small>
              )}
              <a href="#0" className="fav-btn">
                <i className="las la-heart" />
              </a>
            </div>
            <div className="row gx-0">
              <div className="col-5">
                <a href="#" className="img">
                  <img src={fullUrl + product.imageUrl} alt="" className="img-contain" />
                </a>
              </div>
              <div className="col-7">
                <div className="info">
                  {product.rating && (
                    <div className="rating">
                      <div className="stars">
                        {[...Array(5)].map((star, index) => (
                          <i key={index} className={`la la-star ${index < product.rating ? "" : "color-999"}`} />
                        ))}
                      </div>
                      <span className="num">({product.ratingCount})</span>
                    </div>
                  )}
                  <a href="#" className="title fsz-13 fw-bold mb-15 hover-green2 pe-3">
                    {product.title}
                  </a>
                  <h6 className="fsz-16 fw-bold">
                    {product.price}
                    {product.oldPrice && (
                      <span className="old fsz-14 color-666 text-decoration-line-through ms-2">
                        {product.oldPrice}
                      </span>
                    )}
                  </h6>
                </div>
              </div>
            </div>
          </div>
        ))}
      </Slider>
    </section>
  );
};

export default ProductSlider;
