import React, { useState, useEffect } from 'react';
import Slider from 'react-slick';
import TopCategorie from './TopCategorieItem';
import { fetchSuccursale } from '../../../services/apiService';

const CustomArrow = ({ onClick, direction, iconClass }) => {
    return (
        <div className={`cast-${direction}`} onClick={onClick}>
            <i className={iconClass}></i>
        </div>
    );
};

const Succursale = () => {
    const fullUrl = localStorage.getItem("fullUrl");
    const [succursaleData, setsuccursaleData] = useState(null);

    // Récupération des détails de l'événement
    useEffect(() => {
        const params = {
            mode: 'listSuccursale',
            // STR_UTITOKEN: 'AfsvjcsLCHJ68PVdF9ZF',
            // LG_EVEID: localStorage.getItem('LG_EVEID')
        };

        fetchSuccursale(params)
            .then(response => {
                setsuccursaleData(response.data.data);
            })
            .catch(error => {
                console.error('Erreur lors de la récupération des données:', error);
            });
    }, []);

    if (!succursaleData) { // Correction ici
        return <div>Loading...</div>;
    }

    // Configuration du slider
    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 2000,
        responsive: [
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    infinite: true,
                    dots: true
                }
            }
        ],
        prevArrow: <CustomArrow iconClass="swiper-button-prev" direction="prev" />,
        nextArrow: <CustomArrow iconClass="swiper-button-next" direction="next" />
    };

    return (
        <div className="logo-slider categories-slider">
            <Slider {...settings}>
                {succursaleData.map((rowData, rowIndex) => (
                    <div key={rowIndex} className="swiper-slide swiper-slide-active" data-swiper-slide-index="0" role="group" aria-label="1 / 4" style={{ width: '145.5px' }}>
                        <a href="https://ui-themez.smartinnovates.net/items/swoo_html/inner_pages/products.html" className="cat-card">
                            <div className="img">
                                <img src={fullUrl + "assets/img/succursale/" + rowData.STR_SUCCUSALEIMAGE} alt="" className="img-contain" />
                            </div>
                            <p className="fsz-14 fw-600"> {rowData.STR_SUCCUSALERNAME} </p>
                        </a>
                    </div>
                ))}
            </Slider>
        </div>
    );
};

export default Succursale;
