import React, { useState, useEffect } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { fetchSlider } from '../../../services/apiService';

const SliderComponent = () => {
  const fullUrl = localStorage.getItem("fullUrl");
  const [sliderData, setSliderData] = useState(null);

  useEffect(() => {
    const params = {
      mode: 'listSlider',
    };

    fetchSlider(params)
      .then(response => {
        setSliderData(response.data.data);
      })
      .catch(error => {
        console.error('Erreur lors de la récupération des données:', error);
      });
  }, []);

  if (!sliderData) {
    return <div>Loading...</div>;
  }

  // Configuration des paramètres du slider
  const settings = {
    dots: false,
    infinite: sliderData.length > 1,
    autoplay: sliderData.length > 1,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1
  };

  return (
    <Slider {...settings}>
      {sliderData && sliderData.map((image, index) => (
        <div className="swiper-slide" key={index}>
          <div className="slider-card">
            <div className="img">
              <img src={fullUrl + `assets/img/slider/${image.STR_SLIDERIMAGE}`} className="img-cover" alt={`Slide ${image.STR_SLIDERIMAGE}`} />
            </div>
            {/* <div className="info text-white">
              <h2 className="fsz-30 fw-500">
                <span className="color-green2 d-block">aPodOs</span> Work wonders <br /> with easy
              </h2>
              <p className="fsz-12 mt-2">
                Experience aPod 2023 with new <br /> technology from $769
              </p>
              <a href="https://ui-themez.smartinnovates.net/items/swoo_html/inner_pages/single_product.html" className="butn px-3 py-2 bg-green2 text-white radius-4 mt-30 fw-500 fsz-12 text-uppercase">
                <span>discover now</span>
              </a>
            </div> */}
          </div>
        </div>
      ))}
    </Slider>
  );
};

export default SliderComponent;
