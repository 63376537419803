import React from 'react';
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";



const SliderSection = ({sliderData}) => {
  const fullUrl = localStorage.getItem("fullUrl");


  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
  };

  return (
    <section
      className="tc-header-style6 p-30 radius-4 bg-white mt-3 wow fadeInUp"
      style={{ visibility: "visible", animationName: "fadeInUp" }}
    >
      <h6 className="fsz-18 fw-bold text-uppercase mb-30">
        top cell phones &amp; tablets
      </h6>
      <div className="row gx-2">
        <div className="col-lg-8">
          <Slider {...settings}>
            {sliderData && sliderData.map((slide, index) => (
              <div className="header-slider6 swiper-fade swiper-initialized swiper-horizontal swiper-pointer-events swiper-backface-hidden">
              <div className="swiper-wrapper"
                id="swiper-wrapper-9f0103b010fe10b125d"
                aria-live="off"
                style={{ transitionDuration: "0ms" }}
              >
                <div className="swiper-slide swiper-slide-duplicate swiper-slide-duplicate-next"
                  data-swiper-slide-index={2} role="group" aria-label="3 / 3"
                  style={{
                    width: 864,
                    transitionDuration: "0ms",
                    opacity: 1,
                    transform: "translate3d(0px, 0px, 0px)"
                  }}
                >
                  <div className="slider-card text-white">
                    <div className="img">
                      <img
                        src={`assets/img/slider/${slide.STR_SLIDERIMAGE}`}
                        alt=""
                        className="img-cover "
                      />
                    </div>
                  </div>
                </div>
              </div>
              {/* <div className="slider-controls">
                <div
                  className="swiper-button-prev"
                  tabIndex={0}
                  role="button"
                  aria-label="Previous slide"
                  aria-controls="swiper-wrapper-9f0103b010fe10b125d"
                />
                <div className="swiper-pagination swiper-pagination-fraction swiper-pagination-horizontal">
                  <span className="swiper-pagination-current">2</span> /{" "}
                  <span className="swiper-pagination-total">3</span>
                </div>
                <div
                  className="swiper-button-next"
                  tabIndex={0}
                  role="button"
                  aria-label="Next slide"
                  aria-controls="swiper-wrapper-9f0103b010fe10b125d"
                />
              </div>
              <span
                className="swiper-notification"
                aria-live="assertive"
                aria-atomic="true"
              /> */}
            </div>
            ))}
          </Slider>
        </div>
        <div className="col-lg-4 mt-3 mt-lg-0">
          <div className="sub-banner">
            <div className="img">
              <img src={fullUrl + "assets/img/banner1.png"} alt="" className="img-cover" />
            </div>
            <div className="info">
              <div className="row">
                <div className="col-7">
                  <h6 className="fsz-24">redmi note 12 Pro+ 5g</h6>
                  <small className="fsz-12 color-666 mt-10">
                    Rise to the challenge
                  </small>
                </div>
                <div className="col-5 text-end">
                  <a
                    href="../inner_pages/single_product.html"
                    className="butn px-3 py-2 bg-000 text-white radius-4 fw-500 fsz-12 text-uppercase hover-bg-green2"
                  >
                    <span>Shop Now</span>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default SliderSection;
