import React from 'react';

const MissionVisionSection = () => {
  return (
    <section className="tc-mission-vision p-30 radius-4 bg-white mt-3 wow fadeInUp" style={{ visibility: "visible", animationName: "fadeInUp" }}>
      <div className="main-content pb-30 border-bottom">
        <h6 className="fsz-18 fw-bold text-uppercase mb-30"> Our Mission and Vision </h6>
        <div className="text fsz-14 mb-30">
          Nam maximus nunc a augue pulvinar, non euismod mauris tempus. Cras non elit vel magna molestie pellentesque in eu dui. Donec laoreet quis erat vitae finibus. Vestibulum enim eros, porta eget quam et, euismod dictum elit. Nullam eu tempus magna. Fusce malesuada nisi id felis placerat porta vel sed augue. <strong> Vivamus mollis mauris </strong> vitae rhoncus egestas. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas.
        </div>
        <div className="img th-400">
          <img src="assets/img/about/about3.png" alt="" className="img-cover radius-4" />
        </div>
      </div>
      <div className="years-content pt-30 pb-30 border-bottom">
        <h6 className="fsz-18 fw-bold text-uppercase mb-30"> From a Retail Store to the Global Chain of Stores </h6>
        <div className="text fsz-14 mb-30">
          Pellentesque laoreet justo nec ex sodales euismod. Aliquam orci tortor, bibendum nec ultricies ac, auctor nec purus. Maecenas in consectetur erat.
        </div>
        <div className="years">
          <div className="row gx-5">
            <div className="col-lg-6">
              <div className="year-card">
                <strong className="color-000 me-2"> 1997: </strong>
                <span> A small store located in Brooklyn Town, USA </span>
              </div>
              <div className="year-card">
                <strong className="color-000 me-2"> 1998: </strong>
                <span> It is a long established fact that a reader will be distracted by the readable </span>
              </div>
              <div className="year-card">
                <strong className="color-000 me-2"> 2000: </strong>
                <span> Lorem Ipsum is simply dummy text of the printing and typesetting industry </span>
              </div>
              <div className="year-card">
                <strong className="color-000 me-2"> 2002: </strong>
                <span> Lorem Ipsum has been the industry's standard dummy text ever since the </span>
              </div>
              <div className="year-card">
                <strong className="color-000 me-2"> 2004: </strong>
                <span> Contrary to popular belief, Lorem Ipsum is not simply random text </span>
              </div>
              <div className="year-card">
                <strong className="color-000 me-2"> 2005: </strong>
                <span> The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here </span>
              </div>
              <div className="year-card">
                <strong className="color-000 me-2"> 2006: </strong>
                <span> There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. </span>
              </div>
              <div className="year-card">
                <strong className="color-000 me-2"> 2010: </strong>
                <span> All the Lorem Ipsum generators on the Internet tend to repeat predefined </span>
              </div>
              <div className="year-card">
                <strong className="color-000 me-2"> 2013: </strong>
                <span> Lorem Ipsum comes from sections 1.10.32 </span>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="year-card">
                <strong className="color-000 me-2"> 2014: </strong>
                <span> There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form </span>
              </div>
              <div className="year-card">
                <strong className="color-000 me-2"> 2016: </strong>
                <span> All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary </span>
              </div>
              <div className="year-card">
                <strong className="color-000 me-2"> 2020: </strong>
                <span> Lorem Ipsum comes from sections 1.10.32 </span>
              </div>
              <div className="year-card">
                <strong className="color-000 me-2"> 2021: </strong>
                <span> Making this the first true generator on the Internet </span>
              </div>
              <div className="year-card">
                <strong className="color-000 me-2"> 2022: </strong>
                <span> Lorem Ipsum which looks reasonable. The generated Lorem Ipsum is therefore always free from repetition, injected humour </span>
              </div>
              <div className="year-card">
                <strong className="color-000 me-2"> 2023: </strong>
                <span> here are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="leaderships pt-30">
        <div className="title mb-30">
          <div className="row">
            <div className="col-lg-9">
              <h6 className="fsz-18 fw-bold text-uppercase"> Leaderships </h6>
            </div>
            <div className="col-lg-3 text-lg-end mt-4 mt-lg-0">
              <a href="#" className="fsz-13 color-666">
                View All <i className="la la-angle-right ms-1" />
              </a>
            </div>
          </div>
        </div>
        <div className="leaderships-content">
          <div className="leader-card">
            <div className="img">
              <a href="#" className="d-block">
                <img src="assets/img/about/members/mem1.png" alt="" className="main-img img-cover" />
              </a>
              <div className="social-icons">
                <a href="#">
                  <i className="fab fa-twitter" />
                </a>
                <a href="#">
                  <i className="fab fa-linkedin-in" />
                </a>
                <a href="#">
                  <i className="fab fa-instagram" />
                </a>
              </div>
            </div>
            <div className="info pt-15">
              <h6 className="fsz-16 fw-bold">
                <a href="#"> Henry Avery </a>
              </h6>
              <small className="fsz-12 color-666 text-uppercase"> Chairman </small>
            </div>
          </div>
          <div className="leader-card">
            <div className="img">
              <a href="#" className="d-block">
                <img src="assets/img/about/members/mem2.png" alt="" className="main-img img-cover" />
              </a>
              <div className="social-icons">
                <a href="#">
                  <i className="fab fa-twitter" />
                </a>
                <a href="#">
                  <i className="fab fa-linkedin-in" />
                </a>
                <a href="#">
                  <i className="fab fa-instagram" />
                </a>
              </div>
            </div>
            <div className="info pt-15">
              <h6 className="fsz-16 fw-bold">
                <a href="#"> Michael Edward </a>
              </h6>
              <small className="fsz-12 color-666 text-uppercase"> Vice President </small>
            </div>
          </div>
          <div className="leader-card">
            <div className="img">
              <a href="#" className="d-block">
                <img src="assets/img/about/members/mem3.png" alt="" className="main-img img-cover" />
              </a>
              <div className="social-icons">
                <a href="#">
                  <i className="fab fa-twitter" />
                </a>
                <a href="#">
                  <i className="fab fa-linkedin-in" />
                </a>
                <a href="#">
                  <i className="fab fa-instagram" />
                </a>
              </div>
            </div>
            <div className="info pt-15">
              <h6 className="fsz-16 fw-bold">
                <a href="#"> Eden Hazard </a>
              </h6>
              <small className="fsz-12 color-666 text-uppercase"> CEO </small>
            </div>
          </div>
          <div className="leader-card">
            <div className="img">
              <a href="#" className="d-block">
                <img src="assets/img/about/members/mem4.png" alt="" className="main-img img-cover" />
              </a>
              <div className="social-icons">
                <a href="#">
                  <i className="fab fa-twitter" />
                </a>
                <a href="#">
                  <i className="fab fa-linkedin-in" />
                </a>
                <a href="#">
                  <i className="fab fa-instagram" />
                </a>
              </div>
            </div>
            <div className="info pt-15">
              <h6 className="fsz-16 fw-bold">
                <a href="#"> Robert Downey Jr </a>
              </h6>
              <small className="fsz-12 color-666 text-uppercase"> CEO </small>
            </div>
          </div>
          <div className="leader-card">
            <div className="img">
              <a href="#" className="d-block">
                <img src="assets/img/about/members/mem5.png" alt="" className="main-img img-cover" />
              </a>
              <div className="social-icons">
                <a href="#">
                  <i className="fab fa-twitter" />
                </a>
                <a href="#">
                  <i className="fab fa-linkedin-in" />
                </a>
                <a href="#">
                  <i className="fab fa-instagram" />
                </a>
              </div>
            </div>
            <div className="info pt-15">
              <h6 className="fsz-16 fw-bold">
                <a href="#"> Nathan Drake </a>
              </h6>
              <small className="fsz-12 color-666 text-uppercase"> strategist director </small>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default MissionVisionSection;
