import React, { useState, useEffect } from 'react';
import LogoSlider from './LogoSlider'
import { fetchMarque } from '../../../services/apiService';

import LogoToCategorie from './LogoToCategorie'
import MarquesComponent from './MarquesItem'
import TopCategorie from './TopCategorieItem'


const FeaturedBrands = ({nbreLigneAfficher}) => {
    const [marquesData, setmarquesData] = useState(null);

    // Récupération des détails de l'événement
    useEffect(() => {
        const params = {
            mode: 'listMarques',
            // STR_UTITOKEN: 'AfsvjcsLCHJ68PVdF9ZF',
            // LG_EVEID: localStorage.getItem('LG_EVEID')
        };

        fetchMarque(params)
            .then(response => {
                setmarquesData(response.data.data);
            })
            .catch(error => {
                console.error('Erreur lors de la récupération des données:', error);
            });
    }, []);

    if (!marquesData) {
        return <div>Loading...</div>;
    }
    

    

    return (
        <div className="features-brands p-30 radius-4 bg-white mt-3 wow fadeInUp slow" data-wow-delay="0.2s">
            <div className="title mb-4">
                <div className="row">
                    <div className="col-lg-8">
                        <h6 className="fsz-18 fw-bold text-uppercase"> Nos marques & partenaires </h6>
                    </div>
                    <div className="col-lg-4 text-lg-end mt-3 mt-lg-0">
                        <a href="#" className="more text-capitalize color-666 fsz-13"> View All <i className="la la-angle-right ms-1"></i> </a>
                    </div>
                </div>
            </div>
            <LogoSlider logos={marquesData} nbreLigneAfficher={nbreLigneAfficher} />

            
            
        </div>
    );
}

export default FeaturedBrands;
