import React, { useState, useEffect } from 'react';
import { fetchFamille } from '../../../services/apiService';
import { Link } from 'react-router-dom'; // Importation de Link de react-router-dom pour la navigation

function CategoryList() {
    const [familleData, setFamilleData] = useState(null);

    useEffect(() => {
        const params = {
            mode: 'listFamille',
        };

        fetchFamille(params)
            .then(response => {
                // console.log(response.data.data);
                setFamilleData(response.data.data);
            })
            .catch(error => {
                console.error('Erreur lors de la récupération des données:', error);
            });
    }, []);




    const handleClick = (familyId) => {
        localStorage.setItem('LG_FAMILLEID', familyId);
    };

    return (
        <div className="cat-list">
            <ul>
                {familleData && familleData.map((category, index) => (
                    <li key={index}>
                        <Link
                            className="cat-link w-100"
                            to={`/detail-famille`}
                            onClick={() => handleClick(category.LG_FAMILLEID)}
                        >
                            <i className={`${category.STR_FAMILLEICONE} me-2 fs-6`}></i>
                            <span>{category.STR_FAMILLENAME}</span>
                            {/* <i className="las la-angle-right arrow"></i> */}
                            <i className="icon-right-open-mini"></i>
                        </Link>
                    </li>
                ))}
            </ul>
        </div>
    );
}

export default CategoryList;
