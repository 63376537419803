import React from 'react';

const SearchNav = ({ searchTerm, setSearchTerm }) => {
  return (
    <div className="search-nav-style3">
      <div className="container">
        <div className="row align-items-center gx-0">
          <div className="col-lg-5">
            <div className="search-cat">
              <div className="input-group flex-nowrap">
                <select name="name" className="form-select">
                  <option value="">All Categories</option>
                  <option value="">Categories 1</option>
                  <option value="">Categories 2</option>
                </select>
                <input 
                  type="text" 
                  className="form-control" 
                  placeholder="Search anything..." 
                  value={searchTerm} 
                  onChange={(e) => setSearchTerm(e.target.value)} 
                />
                <a href="#" className="search-btn">
                  <i className="fal fa-search"></i>
                </a>
              </div>
            </div>
          </div>
          <div className="col-lg-7 d-none d-lg-block">
            <div className="delivery-features ps-lg-5">
              <div className="row">
                <div className="col-lg-4">
                  <a href="#" className="feat-link">
                    <i className="la la-shipping-fast fs-6 me-2"></i>
                    <span>free shipping over $199</span>
                  </a>
                </div>
                <div className="col-lg-4">
                  <a href="#" className="feat-link">
                    <i className="las la-redo-alt fs-6 me-2"></i>
                    <span>30 days money back</span>
                  </a>
                </div>
                <div className="col-lg-4">
                  <a href="#" className="feat-link">
                    <i className="las la-shield-alt fs-6 me-2"></i>
                    <span>100% secure payment</span>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SearchNav;
