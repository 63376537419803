import React, { useState, useEffect } from 'react';
import Slider from 'react-slick'; // Assurez-vous d'installer react-slick et slick-carousel
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import { fetchHomesectionProduct } from '../../services/apiService';


const fullUrl = localStorage.getItem("fullUrl");
const sliderSettings = {
  dots: true,
  infinite: false, // Pour éviter les boucles infinies si les produits sont moins nombreux
  speed: 500,
  slidesToShow: 5, // Afficher 3 produits à la fois
  slidesToScroll: 1, // Faire défiler 1 produit à la fois
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 1,
        infinite: true,
        dots: true
      }
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
        initialSlide: 2
      }
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1
      }
    }
  ]
};

const Section = ({ section }) => (
  <section className="tc-brand-box-style3 p-30 radius-4 bg-white mt-3">
    <div className="title mb-30">
      <div className="row">
        <div className="col-lg-8">
          <h6 className="fsz-18 fw-bold text-uppercase">{section.STR_HOMESECTIONNAME}</h6>
        </div>
        <div className="col-lg-4 text-lg-end mt-3 mt-lg-0">
          <a href={section.viewAllLink} className="more text-capitalize color-666 fsz-13">View All <i className="la la-angle-right ms-1"></i></a>
        </div>
      </div>
    </div>


    <div class="top-info wow fadeInUp slow" data-wow-delay="0.2s" >
                        <div class="row align-items-center">
                            <div class="col-lg-6">
                                <div class="banner">
                                    <div class="img">
                                    <img src={fullUrl + "assets/img/homesection/" + section.STR_HOMESECTIONBANNER} alt="" className="img-cover" />
                                    </div>
                                    {/* <div class="info">
                                        <h5 class="fsz-24 mb-2 text-uppercase"> redmi note 12 Pro+ 5g </h5>
                                        <p class="fsz-12 color-666 mb-15"> Rise to the challenge </p>
                                        <a href="https://ui-themez.smartinnovates.net/items/swoo_html/inner_pages/single_product.html" class="butn text-white bg-222 radius-3 fw-500 fsz-11 text-uppercase text-center mt-2 hover-bg-green2 py-2 px-3"> <span> shop now </span> </a>
                                    </div> */}
                                </div>
                            </div>
                            <div class="col-lg-6">
                                <div class="brand-numbers">
                                    <div class="row gx-2">
                                      {section.type_products_famille.map((brand, index) => (
                                        <div className="col-lg-4 col-6" key={index}>
                                          <a href={brand.link} className="number-item">
                                            <div className="inf">
                                              <h6 className="fsz-14 fw-bold mb-0 sm-title">{brand.STR_TYPE_PRODUIT_NAME}</h6>
                                              <small className="fsz-12 color-666">{brand.items} Items</small>
                                            </div>
                                            <div className="img">
                                              <img src={fullUrl + `assets/img/typeproduit/${brand.STR_TYPE_PRODUIT_IMAGE}`} alt="" className="img-contain" />
                                            </div>
                                          </a>
                                        </div>
                                      ))}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

    <div className="products-content px-0">
      <Slider className='products-slider' {...sliderSettings}>
        {section.products.map((product, index) => (
          <div key={index} className="product-card">
            <div className="top-inf ">
              {product.discount && (
                <div className="dis-card">
                  <small className="fsz-10 d-block text-uppercase">save</small>
                  <h6 className="fsz-14">${product.discount}</h6>
                </div>
              )}
              {product.new && <small className="fsz-10 py-1 px-2 radius-2 bg-222 text-white text-uppercase">new</small>}
              <a href="#0" className="fav-btn"><i className="las la-heart"></i></a>
            </div>
            <a href={product.link} className="img">
              <img src={fullUrl + "assets/img/produit/" + product.imgSrc} alt="" className="img-contain main-image" />
            </a>
            <div className="info">
              <h6>
                <a href={product.link} className="prod-title fsz-14 fw-bold mt-2 hover-green2">{product.title}</a>
              </h6>
            </div>
          </div>
        ))}
      </Slider>
    </div>
  </section>
);

const FamilleSection = () => {
  const [homesectionProductData, setHomesectionProductData] = useState(null);

  useEffect(() => {
    const params = {
      mode: 'listSectionProduct',
    };

    fetchHomesectionProduct(params)
      .then(response => {
        setHomesectionProductData(response.data.data);
      })
      .catch(error => {
        console.error('Erreur lors de la récupération des données:', error);
      });
  }, []);

  if (!homesectionProductData) {
    return <div>Loading...</div>;
  }

  return (
    <div>
      {homesectionProductData.map((section, index) => <Section key={index} section={section} />)}
    </div>
  );
};

export default FamilleSection;
