import React from 'react';

const ContactSection = () => {
  return (
    <section className="contact p-30 radius-4 bg-white mt-3 wow fadeInUp" style={{ visibility: "visible", animationName: "fadeInUp" }}>
      <h6 className="fsz-18 fw-bold text-uppercase mb-30"> ready to work with us </h6>
      <div className="checkout-form mt-50">
        <div className="row">
          <div className="col-lg-7">
            <p className="fsz-16 color-666 mb-30"> Contact us for all your questions and opinions </p>
            <form className="form">
              <div className="form-content">
                <div className="row gx-3">
                  <div className="col-lg-6">
                    <div className="form-group mb-4">
                      <label htmlFor=""> First Name <span className="color-red1"> * </span> </label>
                      <input type="text" className="form-control" placeholder="" />
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="form-group mb-4">
                      <label htmlFor=""> Last Name <span className="color-red1"> * </span> </label>
                      <input type="text" className="form-control" placeholder="" />
                    </div>
                  </div>
                  <div className="col-lg-12">
                    <div className="form-group mb-4">
                      <label htmlFor=""> Email Address <span className="color-red1"> * </span> </label>
                      <input type="text" className="form-control" placeholder="" />
                    </div>
                  </div>
                  <div className="col-lg-12">
                    <div className="form-group mb-4">
                      <label htmlFor=""> Phone Number <span className="color-666"> (Optional) </span> </label>
                      <input type="text" className="form-control" placeholder="" />
                    </div>
                  </div>
                  <div className="col-lg-12">
                    <div className="form-group mb-4">
                      <label htmlFor=""> Country / Region <span className="color-red1"> * </span> </label>
                      <select name="" id="" className="form-control form-select">
                        <option value=""> United States (US) </option>
                        <option value=""> United States (US) </option>
                      </select>
                    </div>
                  </div>
                  <div className="col-lg-12">
                    <div className="form-group mb-4">
                      <label htmlFor=""> Subject <span className="color-666"> (Optional) </span> </label>
                      <input type="text" className="form-control" placeholder="" />
                    </div>
                  </div>
                  <div className="col-lg-12">
                    <div className="form-group mb-4">
                      <label htmlFor=""> Message </label>
                      <textarea name="" rows={5} className="form-control" placeholder="Note about your order, e.g. special note for delivery " defaultValue={""} />
                    </div>
                  </div>
                  <div className="col-lg-12">
                    <div className="form-check">
                      <input className="form-check-input" type="checkbox" defaultValue="" id="account" />
                      <label className="form-check-label" htmlFor="account">
                        I want to receive news and updates once in a while. By submitting, I’m agreed to the{" "}
                        <a href="#" className="color-green2 text-decoration-underline"> Terms &amp; Conditons </a>
                      </label>
                    </div>
                  </div>
                  <div className="col-lg-12">
                    <a href="#" className="butn bg-green2 text-white radius-4 fw-500 fsz-12 text-uppercase text-center mt-50 py-3"> <span> send message </span> </a>
                  </div>
                </div>
              </div>
            </form>
          </div>
          <div className="col-lg-5">
            <div className="contact-info mt-5 mt-lg-0">
              <div className="contact-info-card">
                <small className="fsz-12 color-666 text-uppercase mb-20"> united states (head quater) </small>
                <ul className="fsz-16 lh-lg">
                  <li> <a href="#"> 152 Thatcher Road St, Mahattan, 10463, US </a> </li>
                  <li> <a href="#"> (+025) 3886 25 16 </a> </li>
                  <li> <a href="#" className="color-green2 text-decoration-underline"> hello@swattechmart.com </a> </li>
                </ul>
                <small className="fsz-12 color-666 text-uppercase mb-20 mt-50"> united kingdom (branch) </small>
                <ul className="fsz-16 lh-lg">
                  <li> <a href="#"> 12 Buckingham Rd, Thornthwaite, HG3 4TY, UK </a> </li>
                  <li> <a href="#"> (+718) 895-5350 </a> </li>
                  <li> <a href="#" className="color-green2 text-decoration-underline"> contact@swattechmart.co.uk </a> </li>
                </ul>
                <div className="social-icons mt-50">
                  <a href="#"> <i className="fab fa-twitter" /> </a>
                  <a href="#"> <i className="fab fa-facebook-f" /> </a>
                  <a href="#"> <i className="fab fa-instagram" /> </a>
                  <a href="#"> <i className="fab fa-youtube" /> </a>
                  <a href="#"> <i className="fab fa-pinterest" /> </a>
                </div>
              </div>
              <div className="img th-380 mt-10">
                <img src="assets/img/contact.png" alt="" className="img-cover radius-4" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default ContactSection;
