import { toast } from 'react-toastify';
import Swal from 'sweetalert2';
import { crudData } from '../services/apiService';

export const handleCreate = (formData,apiUrl, toggleModal, refreshData) => {
    crudData(formData, apiUrl, { headers: { 'Content-Type': 'multipart/form-data' } })
    .then(response => {
      const { desc_statut, code_statut } = response.data;
      if (code_statut === "1") {
        toast.success(desc_statut[0]);
        refreshData();
      } else {
        toast.error(desc_statut[0]);
      }
      toggleModal();
    })
    .catch(error => {
      console.error('Erreur lors de la création de la marque:', error);
      toast.error('Erreur lors de la création de la marque');
    });
};

export const handleUpdate = (formData, apiUrl, toggleModal, refreshData) => {
    crudData(formData, apiUrl, { headers: { 'Content-Type': 'multipart/form-data' } })
    .then(response => {
      const { desc_statut, code_statut } = response.data;
      if (code_statut === "1") {
        toast.success(desc_statut[0]);
        refreshData();
      } else {
        toast.error(desc_statut[0]);
      }
      toggleModal();
    })
    .catch(error => {
      console.error('Erreur lors de la mise à jour de la marque:', error);
      toast.error('Erreur lors de la mise à jour de la marque');
    });
};

export const handleDelete = (params, apiUrl,refreshData) => {
  Swal.fire({
    title: 'Êtes-vous sûr?',
    text: "Vous ne pourrez pas revenir en arrière!",
    icon: 'warning',
    showCancelButton: true,
    confirmButtonColor: '#3085d6',
    cancelButtonColor: '#d33',
    confirmButtonText: 'Oui, supprimer!'
  }).then((result) => {
    if (result.isConfirmed) {
        crudData(params, apiUrl)
        .then(response => {
          toast.success('Marque supprimée avec succès');
          refreshData();
        })
        .catch(error => {
          console.error('Erreur lors de la suppression de la marque:', error);
          toast.error('Erreur lors de la suppression de la marque');
        });
    }
  });
};

// export const handleInputChange = (e, setItem, item) => {
//     const { name, value } = e.target;
//     setItem({ ...item, [name]: value });
//   };
  
export const handleFileChange = (e, setItem, item, field, setPreview) => {
    const file = e.target.files[0];
    setItem({ ...item, [field]: file });
    setPreview(URL.createObjectURL(file));
};

export const handleInputChange = (e, setItem, item) => {
    const { name, value } = e.target;
    setItem(prevItem => ({
      ...prevItem,
      [name]: value
    }));
};
