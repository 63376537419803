import React, { useState, useEffect } from 'react'
import SliderList from  './SliderList'
import FamilleList from  './FamilleList'
import HomeSectionList from  './HomeSectionList'
import MarqueList from  './MarqueList'
import SuccursaleList from  './SuccursaleList'
import TypeProduitList from  './TypeProduitList'
import ProduitList from  './ProduitList'


const fullUrl = localStorage.getItem("fullUrl");

// Tableau d'objets pour les informations des onglets
const tabsData = [
  { id: 1, title: 'Liste slider' },
  { id: 2, title: 'Liste des familles' },
  { id: 3, title: 'Liste des marques' },
  { id: 4, title: 'Home section' },
  { id: 5, title: 'Type produit' },
  { id: 6, title: 'Liste produit' },
  { id: 7, title: 'List' },
  { id: 8, title: 'Liste succursale' }
];

// Composant de menu des onglets
const TabsMenu = ({ tabs, activeTab, setActiveTab }) => {
  return (
    <div className='tabs-side'>
      <div class="main-info">
          <div class="img">
              <img src={ fullUrl + "assets/img/avatar.jpg"} alt="" class="main-img img-cover"/>
          </div>
          <h5 class="fw-bold"> Mark Cole </h5>
          <ul class="mt-2 color-666 lh-lg">
              <li> <a href="#"> swoo@gmail.com </a> </li>
              {/* <li> <a href="#"> Los Angeles, CA </a> </li> */}
          </ul>
      </div>
    <ul className="nav nav-pills mt-4" id="pills-tab" role="tablist">
      {tabs.map(tab => (
        <li className="nav-item w-100" key={tab.id}>
          <button
            className={`nav-link w-100 ${activeTab === tab.id ? 'active' : ''}`}
            onClick={() => setActiveTab(tab.id)}
            id={`pills-prof${tab.id}-tab`}
            data-bs-toggle="pill"
            data-bs-target={`#pills-prof${tab.id}`}
          >
            <span>{tab.title}</span> <i class="icon-right-open-mini"></i>
          </button>
        </li>
      ))}
    </ul>
    </div>
  );
};

// Composant de contenu d'un onglet
const TabContent = ({ tab, activeTab }) => {
  // Fonction pour afficher le contenu en fonction de l'onglet sélectionné
  const renderContent = () => {
    switch (tab.id) {
      case 1:
        return (
          <div className={`tab-pane fade ${activeTab === tab.id ? 'show active' : ''}`} id={`pills-prof${tab.id}`}>
            <h4 className="fw-bold text-capitalize mb-30">{tab.title}</h4>
            <div>
              {/* <p>Contenu pour Account info</p>
              <p>Vous pouvez remplacer cela par les informations du compte de l'utilisateur.</p> */}
              <SliderList  />
            </div>
          </div>
        );
      case 2:
        return (
          <div className={`tab-pane fade ${activeTab === tab.id ? 'show active' : ''}`} id={`pills-prof${tab.id}`}>
            <h4 className="fw-bold text-capitalize mb-30">{tab.title}</h4>
            <div>
              {/* <p>Contenu pour My order</p>
              <p>Vous pouvez remplacer cela par l'historique des commandes de l'utilisateur.</p> */}
               <FamilleList  />
            </div>
          </div>
        );
      case 3:
        return (
          <div className={`tab-pane fade ${activeTab === tab.id ? 'show active' : ''}`} id={`pills-prof${tab.id}`}>
            <h4 className="fw-bold text-capitalize mb-30">{tab.title}</h4>
            <div className=''>
              {/* <p>Contenu pour My address</p>
              <p>Vous pouvez remplacer cela par les informations d'adresse de l'utilisateur.</p> */}
              <MarqueList  />
            </div>
          </div>
        );
        case 4:
        return (
          <div className={`tab-pane fade ${activeTab === tab.id ? 'show active' : ''}`} id={`pills-prof${tab.id}`}>
            <h4 className="fw-bold text-capitalize mb-30">{tab.title}</h4>
            <div className=''>
              {/* <p>Contenu pour My address</p>
              <p>Vous pouvez remplacer cela par les informations d'adresse de l'utilisateur.</p> */}
              <HomeSectionList  />
            </div>
          </div>
        );
        case 5:
        return (
          <div className={`tab-pane fade ${activeTab === tab.id ? 'show active' : ''}`} id={`pills-prof${tab.id}`}>
            <h4 className="fw-bold text-capitalize mb-30">{tab.title}</h4>
            <div className=''>
              {/* <p>Contenu pour My address</p>
              <p>Vous pouvez remplacer cela par les informations d'adresse de l'utilisateur.</p> */}
              <TypeProduitList  />
            </div>
          </div>
        );

        case 6:
        return (
          <div className={`tab-pane fade ${activeTab === tab.id ? 'show active' : ''}`} id={`pills-prof${tab.id}`}>
            <h4 className="fw-bold text-capitalize mb-30">{tab.title}</h4>
            <div className=''>
              {/* <p>Contenu pour My address</p>
              <p>Vous pouvez remplacer cela par les informations d'adresse de l'utilisateur.</p> */}
              <ProduitList  />
            </div>
          </div>
        );

        
      case 8:
        return (
          <div className={`tab-pane fade ${activeTab === tab.id ? 'show active' : ''}`} id={`pills-prof${tab.id}`}>
            <h4 className="fw-bold text-capitalize mb-30">{tab.title}</h4>
            <div>
              <p>Contenu pour Change password</p>
              <p>Vous pouvez remplacer cela par les formulaires de changement de mot de passe.</p>
              <SuccursaleList />
            </div>
          </div>
        );
      default:
        return null;
    }
  };

  return renderContent();
};

// Composant principal
const ProfileSection = () => {
  const [activeTab, setActiveTab] = useState(1); // Définir l'onglet actif par défaut sur 1

  useEffect(() => {
    setActiveTab(1); // Activer le premier onglet par défaut lors du premier rendu
  }, []);

  return (
    <>
      <section className="tc-profile p-30 radius-4 bg-white mt-3 wow fadeInUp mb-3" style={{ visibility: 'visible', animationName: 'fadeInUp' }}>
      <div className="row">
        <div className="col-lg-3">
          <div className="tabs-side mb-4 mb-lg-0">
            <div className="main-info">
              {/* Informations principales */}
            </div>
            <TabsMenu tabs={tabsData} activeTab={activeTab} setActiveTab={setActiveTab} />
          </div>
        </div>
        <div className="col-lg-9 tabs-side">
          <div className="tab-content px-3" id="pills-tabContent">
            {tabsData.map(tab => (
              <TabContent key={tab.id} tab={tab} activeTab={activeTab} />
            ))}
          </div>
        </div>
      </div>
    </section>
    </>
  );
};

export default ProfileSection;
