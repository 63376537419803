import React from 'react';

const CategoriesFamille = ({typeProduitData}) => {
  const fullUrl = localStorage.getItem("fullUrl");

  const categories = [
    {
      title: "iPhone (iOS)",
      items: 74,
      imgSrc: "assets/img/products/prod20.png",
      link: "../inner_pages/products.html",
    },
    {
      title: "Android",
      items: 35,
      imgSrc: "assets/img/products/prod21.png",
      link: "../inner_pages/products.html",
    },
    {
      title: "5G Support",
      items: 12,
      imgSrc: "assets/img/products/prod22.png",
      link: "../inner_pages/products.html",
    },
    {
      title: "Apple Tablets",
      items: 22,
      imgSrc: "assets/img/products/prod62.png",
      link: "../inner_pages/products.html",
    },
    {
      title: "Smartphone Chargers",
      items: 33,
      imgSrc: "assets/img/products/prod63.png",
      link: "../inner_pages/products.html",
    },
    {
      title: "Gaming",
      items: 9,
      imgSrc: "assets/img/products/prod23.png",
      link: "../inner_pages/products.html",
    },
    {
      title: "Xiaomi",
      items: 52,
      imgSrc: "assets/img/products/prod24.png",
      link: "../inner_pages/products.html",
    },
    {
      title: "Accessories",
      items: 29,
      imgSrc: "assets/img/products/prod25.png",
      link: "../inner_pages/products.html",
    },
    {
      title: "Samsung Tablets",
      items: 26,
      imgSrc: "assets/img/products/prod27.png",
      link: "../inner_pages/products.html",
    },
    {
      title: "eReader",
      items: 5,
      imgSrc: "assets/img/products/prod64.png",
      link: "../inner_pages/products.html",
    },
  ];

  return (
    <section
      className="tc-categories-style6 p-30 radius-4 bg-white mt-3 wow fadeInUp"
      style={{ visibility: "visible", animationName: "fadeInUp" }}
    >
      <h6 className="fsz-18 fw-bold text-uppercase mb-30"> Type de produit de la famille </h6>
      <div className="content p-0">
        {typeProduitData && typeProduitData.map((category, index) => (
          <a key={index} href={category.link} className="number-item">
            <div className="inf">
              <h6 className="fsz-14 fw-bold mb-0 sm-title">{category.STR_TYPE_PRODUIT_NAME}</h6>
              {/* <small className="fsz-12 color-666">{category.items} Items</small> */}
            </div>
            <div className="img">
              <img
                src={ fullUrl + `assets/img/typeproduit/${category.STR_TYPE_PRODUIT_IMAGE}`}
                alt={category.STR_TYPE_PRODUIT_NAME}
                className="img-contain"
              />
            </div>
          </a>
        ))}
      </div>
    </section>
  );
};

export default CategoriesFamille;
