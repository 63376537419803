import React, { useState, useEffect } from 'react';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import SearchNav from '../components/header/SearchNav';
import { crudData } from '../../services/apiService';
import { Modal, Button, Form } from 'react-bootstrap';
import Pagination from '../components/pagination/Pagination';
import {
  handleCreate,
  handleUpdate,
  handleDelete,
  handleInputChange,
  handleFileChange
} from '../../utils/formHandlers';

const ProduitList = () => {
    const fullUrl = localStorage.getItem("fullUrl");
    const apiUrl = "ProduitManager.php";
    const apiUrlTypeProduit = "TypeProduitManager.php";
    const apiloadFamilleUrl = "FamilleManager.php";
  
    // State variables
    const [currentPage, setCurrentPage] = useState(1);
    const productsPerPage = 2;
    const [familles, setFamilles] = useState([]);
    const [marquesData, setMarquesData] = useState([]);  // Initialize to empty array
    const [produitData, setProduitData] = useState([]);  // Initialize to empty array

    const [searchTerm, setSearchTerm] = useState("");
    const [editItem, setEditItem] = useState(null);
    const [showModal, setShowModal] = useState(false);
    const [posterPreview, setPosterPreview] = useState(null);
    const [logoPreview, setLogoPreview] = useState(null);
  
    // State for new marque object
    const [newMarque, setNewMarque] = useState({
      LG_FAMILLEID: '',
      LG_TYPE_PRODUIT_ID: '',
      STR_TYPE_PRODUIT_NAME: '',
      STR_PROIMAGE: null,
    });

    // Fetch marques data
    useEffect(() => {
        fetchData({ mode: 'listProduit' }, apiUrl, setProduitData);
      }, []);
  
    // Fetch marques data
    useEffect(() => {
      fetchData({ mode: 'listTypeProduit' }, apiUrlTypeProduit, setMarquesData);
    }, []);
  
    // Fetch famille data
    useEffect(() => {
      fetchData({ mode: 'listFamille' }, apiloadFamilleUrl, setFamilles);
    }, []);
  
    // General fetch function
    const fetchData = (params, url, setData) => {
      crudData(params, url)
        .then(response => {
          setData(response.data.data);
        })
        .catch(error => {
          console.error('Erreur lors de la récupération des données:', error);
        });
    };
  
    // Function to refresh marques data
    const refreshData = () => {
      fetchData({ mode: 'listProduit' }, apiUrl, setProduitData);
    };
  
    // Function to toggle modal visibility and reset file previews
    const toggleModal = () => {
      setShowModal(!showModal);
      setPosterPreview(null);
      setLogoPreview(null);
    };
  
    // Handle edit item
    const handleEdit = (id) => {
      const itemToEdit = produitData.find(produit => produit.LG_PROID === id);
      setEditItem(itemToEdit);
      setLogoPreview(itemToEdit.STR_PROIMAGE ? fullUrl + `assets/img/produit/${itemToEdit.STR_PROIMAGE}` : null);
      toggleModal();
    };
  
    // Filter products based on search term
    const filteredProducts = produitData.filter(catalogue =>
      catalogue.STR_PRONAME.toLowerCase().includes(searchTerm.toLowerCase())
    );
  
    // Pagination calculations
    const indexOfLastProduct = currentPage * productsPerPage;
    const indexOfFirstProduct = indexOfLastProduct - productsPerPage;
    const currentProducts = filteredProducts.slice(indexOfFirstProduct, indexOfLastProduct);
    const totalPages = Math.ceil(filteredProducts.length / productsPerPage);
  
    // Render loading if marques data is not fetched yet
    if (!produitData) {
      return <div>Loading...</div>;
    }
  
    return (
      <div>
        <div className="tc-navbar-container-style3">
          <SearchNav searchTerm={searchTerm} setSearchTerm={setSearchTerm} />
        </div>
        <Button onClick={() => { setEditItem(null); toggleModal(); }}>Add Marque</Button>
  
        <section className="tc-new-brands-style3 p-30 radius-4 bg-white mt-3 wow fadeInUp slow">
          <div className="title mb-30">
            <div className="row align-items-center">
              <div className="col-lg-4 text-lg-end mt-3 mt-lg-0">
                <div className="arrows">
                  <div className="swiper-button-next" tabIndex="0" role="button" aria-label="Next slide"></div>
                  <div className="swiper-button-prev" tabIndex="0" role="button" aria-label="Previous slide"></div>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            {currentProducts.map((rowData, index) => (
              <div className="col-lg-6 mb-4" key={index}>
                <div className="product-card">
                  <div className="top-inf">
                    <small className="fsz-10 py-1 px-2 radius-2 bg-222 text-white text-uppercase"> new </small>
                    <a className="fav-btn" onClick={() => handleEdit(rowData.LG_PROID)}>
                      <FontAwesomeIcon icon={faEdit} style={{ cursor: 'pointer' }} />
                    </a>
                    <a  className="remove-btn" onClick={() => handleDelete(
                      { mode: 'deleteProduit', LG_PROID: rowData.LG_PROID },
                      apiUrl,
                      refreshData)
                    }>
                      <FontAwesomeIcon icon={faTrashAlt} style={{ cursor: 'pointer' }} />
                    </a>
                  </div>
                  <a   className="img">
                    <img src={ fullUrl + `assets/img/produit/${rowData.STR_PROIMAGE}`} alt="" className="img-contain main-image" />
                  </a>
                  <div className="info">
                    <div className="price mt-15">
                      <h5 className="fsz-18 fw-600"> {rowData.STR_PRONAME} </h5>
                    </div>
                    <h6> <a   className="prod-title fsz-14 fw-bold mt-2 hover-green2">{rowData.STR_TYPE_PRODUIT_NAME}</a> </h6>
                    <p className="fsz-12 mt-2"> <i className="fas fa-check-circle color-green2 me-1"></i> En ligne </p>
                  </div>
                </div>
              </div>
            ))}
          </div>
          <Pagination currentPage={currentPage} setCurrentPage={setCurrentPage} totalPages={totalPages} />
        </section>
  
        <Modal show={showModal} onHide={toggleModal}>
          <Modal.Header closeButton>
            <Modal.Title>{editItem ? 'Edit Marque' : 'Add Marque'}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <form>
              <Form.Control
              as="select"
              value={editItem ? editItem.LG_FAMILLEID : newMarque.LG_FAMILLEID}
              onChange={(e) => handleInputChange(e, editItem ? setEditItem : setNewMarque, editItem ? editItem : newMarque)}
              name="LG_FAMILLEID"
            >
              <option value="">Sélectionnez une famille</option>
              {familles.map((famille) => (
                <option key={famille.LG_FAMILLEID} value={famille.LG_FAMILLEID}>
                  {famille.STR_FAMILLENAME}
                </option>
              ))}
            </Form.Control>
            <Form.Control
              as="select"
              value={editItem ? editItem.LG_TYPE_PRODUIT_ID : newMarque.LG_TYPE_PRODUIT_ID}
              onChange={(e) => handleInputChange(e, editItem ? setEditItem : setNewMarque, editItem ? editItem : newMarque)}
              name="LG_TYPE_PRODUIT_ID"
            >
              <option value="">Sélectionnez type de produit</option>
              {marquesData.map((typeproduit) => (
                <option key={typeproduit.LG_TYPE_PRODUIT_ID} value={typeproduit.LG_TYPE_PRODUIT_ID}>
                  {typeproduit.STR_TYPE_PRODUIT_NAME}
                </option>
              ))}
            </Form.Control>
              <div className="form-group">
                <label htmlFor="STR_TYPE_PRODUIT_NAME">Nom de la marque</label>
                <input
                  type="text"
                  className="form-control"
                  id="STR_PRONAME"
                  name="STR_PRONAME"
                  value={editItem ? editItem.STR_PRONAME : newMarque.STR_PRONAME}
                  onChange={(e) => handleInputChange(e, editItem ? setEditItem : setNewMarque, editItem ? editItem : newMarque)}
                />
              </div>
              <div className="form-group">
                <label htmlFor="STR_PROIMAGE">Image du produit:</label>
                <input
                  type="file"
                  className="form-control"
                  id="STR_PROIMAGE"
                  onChange={(e) => handleFileChange(e, editItem ? setEditItem : setNewMarque, editItem ? editItem : newMarque, 'STR_PROIMAGE', setLogoPreview)}
                />
                {logoPreview && <img src={logoPreview} alt="Logo Preview" style={{ width: '100px', height: 'auto', marginTop: '10px' }} />}
              </div>
            </form>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={toggleModal}>
              Close
            </Button>
            <Button className='radius-4 bg-green2 border-none' onClick={() => {
              const formData = new FormData();
              formData.append('LG_TYPE_PRODUIT_ID', editItem ? editItem.LG_TYPE_PRODUIT_ID : newMarque.LG_TYPE_PRODUIT_ID);
              formData.append('LG_FAMILLEID', editItem ? editItem.LG_FAMILLEID : newMarque.LG_FAMILLEID);
              formData.append('STR_PRONAME', editItem ? editItem.STR_PRONAME : newMarque.STR_PRONAME);
              if (editItem) {
                formData.append('mode', "updateProduit");
                formData.append('LG_PROID', editItem.LG_PROID);
                if (editItem.STR_PROIMAGE instanceof File) {
                  formData.append('STR_PROIMAGE', editItem.STR_PROIMAGE);
                }
                handleUpdate(formData ,apiUrl, toggleModal, refreshData);
              } else {
                formData.append('STR_PROIMAGE', newMarque.STR_PROIMAGE);
                formData.append('mode', "createProduit");
                handleCreate(formData,apiUrl, toggleModal, refreshData);
              }
            }}>
              {editItem ? 'Save Changes' : 'Add Marque'}
            </Button>
          </Modal.Footer>
        </Modal>
  
        <ToastContainer />
      </div>
    );
  };
  
  export default ProduitList;
  
