// src/services/apiService.js

import axios from 'axios';

const getRootUrl = (port) => {
  const originWithoutPort = window.location.protocol + '//' + window.location.hostname;
  const defaultPort = "80";
  const baseUrl = "/MES_PROJETS/sodismadci/public/webservices/";
  // const baseUrl = "/webservices/";
  const finalPort = port ? port : defaultPort;
  return `${originWithoutPort}:${finalPort}${baseUrl}`;
};


// Je set la racine de l'url dans la session
const getFullUrl = () => {
  const { protocol, hostname, port } = window.location;
  const portPart = port ? `:${port}` : '';
  return `${protocol}//${hostname}${portPart}/`;
};

const fullUrl = getFullUrl();
localStorage.setItem("fullUrl", fullUrl)




// +++++++++++++++ DEPLOIEMENT +++++++++++++++++++

//const rootUrl = getRootUrl("80"); //Dev
const rootUrl = fullUrl + "webservices/"; //Production

// +++++++++++++++ FONCTION +++++++++++++++++++


const fetchEvenements = (params) => {
  return axios.post(`${rootUrl}TicketManager.php`, params, {
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    }
  });
};

// Vous pouvez ajouter d'autres fonctions pour d'autres appels API ici
// Par exemple, une fonction pour obtenir les détails d'un événement
const fetchEvenementDetails = (eventId) => {
  const params = {
    mode: 'getEvenementDetails',
    STR_UTITOKEN: 'wzpjku2dvokriz3phgop',
    LG_EVENID: eventId
  };

  return axios.post(`${rootUrl}TicketManager.php`, params, {
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    }
  });
};


const doConnexion = (params) => {
  return axios.post(`${rootUrl}Authentification.php`, params, {
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    }
  });
};

const doDisConnexion = (params) => {
  return axios.post(`${rootUrl}Authentification.php`, params, {
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    }
  });
};



// SODISMADCI

const fetchSlider = (params) => {
  return axios.post(`${rootUrl}SliderManager.php`, params, {
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    }
  });
};

const fetchFamille = (params) => {
  return axios.post(`${rootUrl}FamilleManager.php`, params, {
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    }
  });
};


const fetchMarque = (params) => {
  return axios.post(`${rootUrl}MarquesManager.php`, params, {
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    }
  });
};


const createMarque = (params) => {
  return axios.post(`${rootUrl}MarquesManager.php`, params, {
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    }
  });
};


const updateMarque = (params) => {
  return axios.post(`${rootUrl}MarquesManager.php`, params, {
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    }
  });
};


const deleteMarque = (params) => {
  return axios.post(`${rootUrl}MarquesManager.php`, params, {
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    }
  });
};

// ----

const fetchSuccursale = (params) => {
  return axios.post(`${rootUrl}SuccursaleManager.php`, params, {
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    }
  });
};

const fetchHomesectionProduct = (params) => {
  return axios.post(`${rootUrl}SectionProductManager.php`, params, {
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    }
  });
};


const fetchCatalogue = (params) => {
  return axios.post(`${rootUrl}CatalogueManager.php`, params, {
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    }
  });
};



// GLOBAL CRUD

const crudData = (params,apiUrl) => {
  return axios.post(`${rootUrl}${apiUrl}`, params, {
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    }
  });
};




export { fetchSlider,fetchFamille,fetchMarque, fetchSuccursale, fetchHomesectionProduct, fetchCatalogue, 
  deleteMarque, updateMarque, createMarque ,

  crudData,
  
  fetchEvenements, fetchEvenementDetails, doConnexion, doDisConnexion };
