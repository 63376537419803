import React from 'react';

const data = [
  {
    imgSrc: 'assets/img/ban1.png',
    type: 'main-card',
    title: 'Best experience always wins',
    description: '#1 Online Marketplace for Electronic & Technology in Mahanttan, CA',
    stats: [
      {
        value: '$12,5M',
        description: 'total revenue from 2001 - 2023'
      },
      {
        value: '12K+',
        description: 'orders delivered successful on everyday'
      },
      {
        value: '725+',
        description: 'store and office in U.S and worldwide'
      }
    ]
  }
];

const AboutSection = () => {
  return (
    <section
  className="tc-main-about p-30 radius-4 bg-white mt-3 wow fadeInUp"
  style={{ visibility: "visible", animationName: "fadeInUp" }}
>
  <div className="main-card">
    <div className="about-banner">
      <h1 className="fw-400 fsz-45">
        {" "}
        <strong> Best experience </strong> <br /> always wins{" "}
      </h1>
      <p className="fsz-14 color-666 mt-30">
        {" "}
        #1 Online Marketplace for Electronic &amp; Technology <br /> in
        Mahanttan, CA{" "}
      </p>
    </div>
    <div className="about-numbers">
      <div className="row">
        <div className="col-lg-5">
          <div className="info-text">
            <h6 className="fsz-18 text-uppercase fw-bold lh-5">
              {" "}
              our purpose is to <span className="color-green2">
                {" "}
                enrich{" "}
              </span>{" "}
              <br /> <span className="color-green2">
                {" "}
                and enhance lives{" "}
              </span>{" "}
              through <br /> technology{" "}
            </h6>
          </div>
        </div>
        <div className="col-lg-7">
          <div className="numbers">
            <div className="row justify-content-between">
              <div className="col-lg-4">
                <div className="num-card mt-4 mt-lg-0">
                  <h2 className="fsz-40 text-uppercase"> $12,5M </h2>
                  <p className="fsz-12 color-666 text-uppercase">
                    {" "}
                    total revenue from <br /> 2001 - 2023{" "}
                  </p>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="num-card mt-4 mt-lg-0">
                  <h2 className="fsz-40 text-uppercase"> 12K+ </h2>
                  <p className="fsz-12 color-666 text-uppercase">
                    {" "}
                    orders delivered <br /> successful on everyday{" "}
                  </p>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="num-card mt-4 mt-lg-0">
                  <h2 className="fsz-40 text-uppercase"> 725+ </h2>
                  <p className="fsz-12 color-666 text-uppercase">
                    {" "}
                    store and office in U.S <br /> and worldwide{" "}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div className="sub-cards">
    <div className="row gx-2">
      <div className="col-lg-6 mt-3">
        <div className="img">
          <img src="assets/img/about/about2.png" alt="" className="img-cover" />
        </div>
      </div>
      <div className="col-lg-6 mt-3">
        <div className="info-card">
          <div className="cont">
            <h6 className="fsz-18 fw-bold">
              {" "}
              We connect millions of buyers and sellers around the world,
              empowering people &amp; creating economic opportunity for all.{" "}
            </h6>
            <p className="fsz-14 color-666 mt-30">
              {" "}
              Within our markets, millions of people around the world connect,
              both online and offline, to make, sell and buy unique goods. We
              also offer a wide range of Seller Services and tools that help
              creative entrepreneurs start, manage &amp; scale their businesses.{" "}
            </p>
            <a
              href="https://www.youtube.com/watch?v=qYgdnM3BC3g"
              className="butn bg-green2 text-white radius-4 fw-500 fsz-12 text-uppercase text-center mt-40 py-3"
              data-fancybox=""
            >
              {" "}
              <span>
                {" "}
                <i className="la la-play me-1" /> our showreel{" "}
              </span>{" "}
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

  );
};

export default AboutSection;
