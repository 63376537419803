import React from 'react';
import AboutSection from './AboutSection';
import NosAtous from './NosAtous';
import MissionVisionSection from './MissionVisionSection';


import Header from '../components/Header'; // Importation du composant Header
import Footer from '../components/Footer'; // Importation du composant Header



const Apropos = () => {
  return (
    <>
        

    <div class="body-container ">
        <div class="container">
            <Header /> 
            <div className='about-pg-1'>
                <AboutSection />

                <NosAtous />

                <MissionVisionSection />
            </div>
        </div>
    </div>

    <div className='tc-footer-style3'>
      <div className='container'>
        <Footer /> 
      </div>
    </div>
    </>
  );
};

export default Apropos;
